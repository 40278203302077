import React, { useState } from "react";
import Markdown from "markdown-to-jsx";

import { Box, Image, Text, Button, Flex } from "atoms";
import { VideoModal } from "molecules";

export const BookCard = ({
  gradient,
  title,
  info,
  // btnText,
  img,
  // btnLink,
  btnPrimary,
  readMore,
  watchVideo,
}) => {
  const [showVideo, setShowVideo] = useState(false);

  return (
    <>
      {showVideo && (
        <VideoModal
          isOpen={showVideo}
          onRequestClose={() => setShowVideo(false)}
          ariaHideApp={false}
          title="What is Down syndrome?"
          thumbnail="/images/thumbnail.png"
          videoUrl="https://www.youtube.com/watch?v=ysz5S6PUM-U"
        />
      )}
      <Box
        maxWidth="125.5rem"
        height="54.4rem"
        mx="auto"
        boxShadow={
          gradient === "primary" && "inset 0px 4px 8px rgba(0, 0, 0, 0.16)"
        }
        background={
          gradient === "primary"
            ? "linear-gradient(90deg, #652D90 0%, rgba(111, 57, 150, 0) 100%)"
            : gradient === "white" &&
              "linear-gradient(269.85deg, rgba(255, 255, 255, 0.91) 44.7%, rgba(255, 255, 255, 0) 96.99%)"
        }
      >
        <Image
          src={img}
          alt="book-card"
          style={{
            zIndex: "-1",
          }}
        />

        <Flex
          position="absolute"
          flexDirection="column"
          top="5.3rem"
          left={gradient === "primary" && "7.1rem"}
          right={gradient === "white" && "5.6rem"}
        >
          <Text
            fontSize="3.5rem"
            lineHeight="5.2rem"
            fontWeight="700"
            fontFamily="primaryBlack"
            color={gradient === "primary" ? "white" : "secondary.900"}
            mb="2.2rem"
          >
            {title}
          </Text>
          <Box
            maxWidth="62.5rem"
            maxHeight="30rem"
            fontSize="1.6rem"
            // lineHeight="3.4rem"
            fontWeight="500"
            fontFamily="primaryMedium"
            color={gradient === "primary" ? "white" : "secondary.900"}
            textColor={gradient === "primary" ? "white" : "secondary.900"}
            // mb="4.8rem"
            overflow="hidden"
            wrap="nowrap"
          >
            <Markdown
              options={{
                overrides: {
                  p: {
                    props: {
                      style: {
                        color: `${
                          gradient === "primary"
                            ? "white !important"
                            : "secondary.900 !important"
                        }`,
                      },
                    },
                  },
                  span: {
                    props: {
                      style: {
                        color: `${
                          gradient === "primary"
                            ? "white !important"
                            : "secondary.900 !important"
                        }`,
                        height: "min-content",
                      },
                    },
                  },
                },
              }}
            >
              {info}
            </Markdown>
          </Box>
          {/* {info.split("<br/>").map((item, index) => (
            <Text
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              maxWidth="62.5rem"
              fontSize="1.6rem"
              lineHeight="3.4rem"
              fontWeight="500"
              fontFamily="primaryMedium"
              color={gradient === "primary" ? "white" : "secondary.900"}
              mb="4.8rem"
            >
              {item}
            </Text>
          ))} */}
          <a href={btnPrimary?.link} target="__blank" rel="noreferrer">
            <Button
              // mt="2.1rem"
              width="62.5rem"
              variant={gradient === "primary" ? "white" : "primary"}
            >
              {btnPrimary?.text}
            </Button>
          </a>

          <Flex
            alignItems="center"
            // justifyContent="space-between"
            justifyContent={
              readMore?.link || watchVideo?.link
                ? readMore?.link && watchVideo?.link
                  ? "space-between"
                  : "flex-end"
                : ""
            }
            mt="1.1rem"
            width="62.5rem"
          >
            {readMore?.link && (
              <a href={readMore?.link} target="__blank" rel="noreferrer">
                <Text
                  fontSize="2rem"
                  lineHeight="3rem"
                  fontWeight="600"
                  fontFamily="primaryRegular"
                  color={gradient === "primary" ? "white" : "secondary.500"}
                  borderBottom="1px solid"
                  borderColor={
                    gradient === "primary" ? "white" : "secondary.500"
                  }
                  cursor="pointer"
                >
                  {readMore?.text}
                </Text>
              </a>
            )}
            {watchVideo?.link && (
              <a href={watchVideo?.link} target="__blank" rel="noreferrer">
                <Text
                  onClick={() => setShowVideo(true)}
                  cursor="pointer"
                  fontSize="2rem"
                  lineHeight="3rem"
                  fontWeight="600"
                  fontFamily="primaryRegular"
                  color={gradient === "primary" ? "white" : "secondary.500"}
                  borderBottom="1px solid"
                  borderColor={
                    gradient === "primary" ? "white" : "secondary.500"
                  }
                >
                  {watchVideo?.text}
                </Text>
              </a>
            )}
          </Flex>
        </Flex>
      </Box>
    </>
  );
};
