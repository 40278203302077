import React, {
  createRef,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Box, Flex, Text, Button } from "atoms";
import useAuth from "contexts/Auth";
import { InfoModal } from "molecules";
import { ReportContext } from "utils/Contexts";
import { strappiQueryFilterCondition } from "utils/utilities";
import MemoArrow from "assets/icons/Arrow";
import {
  AboutTest,
  Results,
  SpecimenDetails,
  WhatNext,
  WhatNextCards,
} from "./molcules";

const btnTitle = [
  { id: "0", name: "View results overview", to: "1" },
  { id: "1", name: "What next", to: "2" },
  { id: "2", name: "Specimen details", to: "3" },
  { id: "3", name: "What next?", to: "2" },
];

export const ReportPage = () => {
  const { activeReportId, setActiveReportId, setShowNav } =
    useContext(ReportContext);
  const [showModal, setShowModal] = useState(false);
  const [scroll, setScroll] = useState(false);

  const handleScroll = () => {
    const h = document.documentElement;
    const b = document.body;
    const st = "scrollTop";
    const sh = "scrollHeight";

    const percent =
      ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100;
    if (percent >= 25 && percent < 100) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  // useEffect(() => {
  //   const h = document.documentElement;
  //   const b = document.body;
  //   const st = "scrollTop";
  //   const sh = "scrollHeight";

  //   const percent =
  //     ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100;
  //   setArrowTop(percent);
  // }, [
  //   document.documentElement.scrollTop,
  //   document.documentElement.scrollHeight,
  //   document.documentElement.clientHeight,
  //   document.body.scrollTop,
  //   document.body.scrollHeight,
  // ]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  useEffect(() => {
    setShowNav(true);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeReportId]);

  const reportSlider = createRef();

  const gotoNext = () => {
    reportSlider.current.slickNext();
  };

  const gotoPrev = () => {
    reportSlider.current.slickPrev();
  };

  useEffect(() => {
    reportSlider.current.slickGoTo(parseInt(activeReportId, 10));
  }, [activeReportId]);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    adaptiveHeight: true,
    swipe: false,
    beforeChange: (current, next) => {
      setActiveReportId(String(next));
    },
  };

  const {
    state: { report, testInfo, testBanners, testDisorders, headings },
  } = useAuth();

  const [interpretationStrappi, setInterpretationStrappi] = useState([]);
  const [snfStrappi, setsnfStrappi] = useState([]);

  // interpretation-rule-engines
  // snf-rule-engines
  const fetchInterpretationStrappi = useCallback(
    async (
      syndrome_name,
      interpretation_risk,
      affected_foetus,
      total_foetus
      // eslint-disable-next-line consistent-return
    ) => {
      try {
        const test = await fetch(
          // eslint-disable-next-line prefer-template
          `${process.env.REACT_APP_STRAPI}/interpretation-rule-engines?` +
            new URLSearchParams({
              syndrome_name,
              interpretation_risk,
              affected_foetus,
              total_foetus,
            })
        );

        const testdata = await test.json();
        return testdata;
        // setInterpretationStrappi(testdata[0] || {});
      } catch (e) {
        console.error(e);
        return [];
      }
    },
    []
  );
  const fetchSnfStrappi = useCallback(
    async (
      syndrome_name,
      interpretation_risk,
      affected_foetus,
      total_foetus
      // eslint-disable-next-line consistent-return
    ) => {
      try {
        const test = await fetch(
          // eslint-disable-next-line prefer-template
          `${process.env.REACT_APP_STRAPI}/snf-rule-engines?` +
            new URLSearchParams({
              syndrome_name,
              interpretation_risk,
              affected_foetus,
              total_foetus,
            })
        );

        const testdata = await test.json();
        return testdata;
        // setInterpretationStrappi(testdata[0] || {});
      } catch (e) {
        console.error(e);
        return [];
      }
    },
    []
  );

  // snf mom
  const fetchSnfMomValidationStrappi = useCallback(
    async (
      key
      // eslint-disable-next-line consistent-return
    ) => {
      try {
        const test = await fetch(
          // eslint-disable-next-line prefer-template
          `${process.env.REACT_APP_STRAPI}/snf-mom-validation-engines?` +
            new URLSearchParams({
              key,
            })
        );

        const testdata = await test.json();
        return testdata;
        // setInterpretationStrappi(testdata[0] || {});
      } catch (e) {
        console.error(e);
        return [];
      }
    },
    []
  );
  const fetchSnfMomStrappi = useCallback(
    async (
      key
      // eslint-disable-next-line consistent-return
    ) => {
      try {
        const QUERY_OBJECT = {
          key,
        };

        const test = await fetch(
          // eslint-disable-next-line prefer-template
          `${process.env.REACT_APP_STRAPI}/snf-mom-engines?` +
            new URLSearchParams(QUERY_OBJECT)
        );

        const testdata = await test.json();
        return testdata;
        // setInterpretationStrappi(testdata[0] || {});
      } catch (e) {
        console.error(e);
        return [];
      }
    },
    []
  );

  const handleInterpretationsAndSuggestions = useCallback(() => {
    // number of foetus
    const total_foetus = Object.keys(report?.testResult || {})?.length;

    // interpretations and snf
    const interpretations = [];
    const snfs = [];

    // cases for interpretation according to priority
    const highCases =
      report?.interpretationsJson?.filter((item) => {
        const screenValue = item?.screen?.toString()?.toLowerCase();
        return (
          item?.type === "risk" &&
          (screenValue?.includes("high") ||
            screenValue?.includes("positive") ||
            screenValue?.includes("abnormal") ||
            screenValue?.includes("intermediate"))
        );
      }) || [];

    const lowCases =
      report?.interpretationsJson?.filter((item) => {
        const screenValue = item?.screen?.toString()?.toLowerCase();
        return (
          item?.type === "risk" &&
          (screenValue?.includes("low") ||
            screenValue?.includes("negative") ||
            screenValue?.includes("normal"))
        );
      }) || [];

    const floatingCaseList = highCases?.length
      ? highCases
      : lowCases?.slice(0, 1);

    floatingCaseList?.forEach(async (item) => {
      const syndrome_name = item?.key;
      let interpretation_risk = "";
      let affected_foetus = 0;

      // setting interpretation risk
      if (
        item?.screen?.toString()?.toLowerCase()?.includes("high") ||
        item?.screen?.toString()?.toLowerCase()?.includes("positive") ||
        item?.screen?.toString()?.toLowerCase()?.includes("abnormal")
      ) {
        interpretation_risk = "high";
      } else if (
        item?.screen?.toString()?.toLowerCase()?.includes("intermediate")
      ) {
        interpretation_risk = "intermediate";
      } else {
        interpretation_risk = "low";
      }

      // setting affected foetus
      for (let i = 1; i <= total_foetus; i++) {
        const result =
          report?.risks?.[item?.key]?.[`resultFetus${i}`] ||
          report?.risks?.[item?.key]?.result ||
          item?.screen ||
          "";
        if (
          !(
            result?.toString()?.toLowerCase()?.includes("low") ||
            result?.toString()?.toLowerCase()?.includes("negative") ||
            result?.toString()?.toLowerCase()?.includes("normal")
          )
        ) {
          affected_foetus++;
        }
      }

      // loading interpretation and snf
      const itemInterpretation = await fetchInterpretationStrappi(
        syndrome_name,
        interpretation_risk,
        affected_foetus,
        total_foetus
      );
      interpretations.push(...itemInterpretation);
    });

    setInterpretationStrappi(interpretations);
    // interpretation json object

    // cases for interpretation according to priority
    const highCasesSnf =
      report?.interpretationsJson?.filter((item) => {
        const screenValue = item?.screen?.toString()?.toLowerCase();
        return (
          item?.type === "mom" &&
          (screenValue?.includes("high") ||
            screenValue?.includes("positive") ||
            screenValue?.includes("abnormal") ||
            screenValue?.includes("intermediate"))
        );
      }) || [];

    const lowCasesSnf =
      report?.interpretationsJson?.filter((item) => {
        const screenValue = item?.screen?.toString()?.toLowerCase();
        return (
          item?.type === "mom" &&
          (screenValue?.includes("low") ||
            screenValue?.includes("negative") ||
            screenValue?.includes("normal"))
        );
      }) || [];

    const floatingCaseListSnf = [
      ...floatingCaseList,
      ...(highCasesSnf?.length ? highCasesSnf : lowCasesSnf),
    ];

    floatingCaseListSnf?.forEach(async (item) => {
      if (item?.type === "risk") {
        const syndrome_name = item?.key;
        let interpretation_risk = "";
        let affected_foetus = 0;

        // setting interpretation risk
        if (
          item?.screen?.toString()?.toLowerCase()?.includes("high") ||
          item?.screen?.toString()?.toLowerCase()?.includes("positive") ||
          item?.screen?.toString()?.toLowerCase()?.includes("abnormal")
        ) {
          interpretation_risk = "high";
        } else if (
          item?.screen?.toString()?.toLowerCase()?.includes("intermediate")
        ) {
          interpretation_risk = "intermediate";
        } else {
          interpretation_risk = "low";
        }

        // setting affected foetus
        for (let i = 1; i <= total_foetus; i++) {
          const result =
            report?.risks?.[item?.key]?.[`resultFetus${i}`] ||
            report?.risks?.[item?.key]?.result ||
            item?.screen ||
            "";
          if (
            !(
              result?.toString()?.toLowerCase()?.includes("low") ||
              result?.toString()?.toLowerCase()?.includes("negative") ||
              result?.toString()?.toLowerCase()?.includes("normal")
            )
          ) {
            affected_foetus++;
          }
        }

        // loading interpretation and snf
        // const itemInterpretation = await fetchInterpretationStrappi(
        //   syndrome_name,
        //   interpretation_risk,
        //   affected_foetus,
        //   total_foetus
        // );
        const itemSnf = await fetchSnfStrappi(
          syndrome_name,
          interpretation_risk,
          affected_foetus,
          total_foetus
        );

        // interpretations.push(...itemInterpretation);
        snfs.push(...itemSnf);
      }

      if (item?.type === "mom") {
        const validationObject = await fetchSnfMomValidationStrappi(item?.key);
        if (validationObject?.length) {
          // old query style
          // const queryString = validationObject?.[0]?.query;
          // const queryValue = item?.value;
          let affected_foetus = 0;

          // setting affected foetus
          for (let i = 1; i <= total_foetus; i++) {
            const result =
              report?.risks?.[item?.key]?.[`resultFetus${i}`] ||
              report?.risks?.[item?.key]?.result ||
              item?.screen ||
              "";
            if (
              !(
                result?.toString()?.toLowerCase()?.includes("low") ||
                result?.toString()?.toLowerCase()?.includes("negative") ||
                result?.toString()?.toLowerCase()?.includes("normal")
              )
            ) {
              affected_foetus++;
            }
          }

          validationObject?.forEach(async (validationItem) => {
            const queries = validationItem?.queryComponent;
            const queryValue = item?.value;

            let isValid = false;
            const num_queries = queries?.length || 0;

            for (let i = 0; i < num_queries; i++) {
              if (
                !strappiQueryFilterCondition(
                  queries?.[i]?.type,
                  queryValue,
                  queries?.[i]?.value
                )
              ) {
                isValid = false;
                break;
              } else {
                isValid = true;
              }
            }

            // test window check
            if (
              isValid &&
              validationItem?.test_window &&
              report?.additionalInfo?.testICDCode !==
                validationItem?.test_window
            ) {
              isValid = false;
            }

            // total_foetus check
            if (
              isValid &&
              validationItem?.total_foetus &&
              total_foetus !== validationItem?.total_foetus
            ) {
              isValid = false;
            }

            // infected_foetus check
            if (
              isValid &&
              validationItem?.infected_foetus &&
              affected_foetus !== validationItem?.infected_foetus
            ) {
              isValid = false;
            }

            // test_window
            // total_foetus
            // infected_foetus

            if (isValid) {
              const itemSnfMom = await fetchSnfMomStrappi(
                validationItem?.linkKey
              );
              snfs.push(...itemSnfMom);
            }
          });

          // const itemSnfMom = await fetchSnfMomStrappi(
          //   item?.key,
          //   total_foetus,
          //   queryString,
          //   queryValue
          // );
          // snfs.push(...itemSnfMom);
        }
      }
    });

    setsnfStrappi(snfs);
  }, [
    report,
    setsnfStrappi,
    fetchSnfMomStrappi,
    fetchSnfMomValidationStrappi,
    fetchInterpretationStrappi,
  ]);

  useEffect(() => {
    if (report) {
      handleInterpretationsAndSuggestions();
    }
  }, [report, handleInterpretationsAndSuggestions]);

  const getDisorderName = useCallback(
    (key) => {
      const selectedDisorder = testDisorders?.find(
        (disorder) => disorder?.key === key
      );

      return selectedDisorder?.name || "";
    },
    [testDisorders]
  );

  const getDisorderTooltip = useCallback(
    (key) => {
      const selectedDisorder = testDisorders?.find(
        (disorder) => disorder?.key === key
      );

      return selectedDisorder?.description || "";
    },
    [testDisorders]
  );

  return (
    <Box height="100%">
      {showModal && (
        <InfoModal
          isOpen={showModal}
          onRequestClose={() => setShowModal(false)}
          ariaHideApp={false}
          title={getDisorderName(showModal)}
          info={getDisorderTooltip(showModal)}
          height={{ xs: "35rem" }}
          keyTooltip={showModal}
        />
      )}
      <Box
        px={{ xs: "3rem", lg: "7rem" }}
        pt={{ xs: "5.4rem", lg: "10rem" }}
        pb={{ xs: "4.8rem", lg: "9rem" }}
      >
        {/* main content */}
        <Box className="home-slider report-slider" zIndex="2">
          <Slider {...settings} ref={reportSlider}>
            <AboutTest
              scroll={scroll}
              heading={testInfo?.name || ""}
              // heading={report?.additionalInfo?.testName}
              testingWindow={report?.additionalInfo?.testICDCode}
              sampleType={report?.testMethodology?.sampleType}
              description={testInfo?.description}
              logoUrl={testInfo?.test_image?.url}
            />
            <Results
              sampleId={report?.sampleInformation?.sampleId}
              risks={report?.risks}
              MOM={report?.testResultMom}
              interpretation={interpretationStrappi}
              logoUrl={testInfo?.test_image?.url}
              testResult={report?.testResult}
              headings={headings?.find((item) => item?.slug === "report")}
            />
            <WhatNext
              sNf={snfStrappi}
              videos={testBanners?.videoList}
              headings={headings?.find((item) => item?.slug === "what-next")}
            />
            <SpecimenDetails
              scanDetails={report?.scanDetails}
              scanUnits={report?.scanDetailsUnit}
              MOM={report?.testResultMom}
              headings={headings?.find(
                (item) => item?.slug === "specimen-details"
              )}
            />
          </Slider>
        </Box>

        {/* arrow slider thingy */}
        <Box position="absolute" top="20rem" left="0%" width="100%" zIndex="1">
          <Box
            alignItems="center"
            justifyContent="space-between"
            mt="8rem"
            display={{ xs: "none", lg: "flex" }}
            position="fixed"
            mx="auto"
            // width="100%"
            // px={{ lg: "6.6rem" }}
            // width={{ xs: "100vw", lg: "144rem" }}
            width="100%"
            maxWidth={{ lg: "144rem" }}
          >
            <Flex
              width="5.6rem"
              height="5.6rem"
              // borderRadius="50%"
              // boxShadow="0px 4px 8px rgba(0, 0, 0, 0.4)"
              opacity="0.6"
              // border="0.2rem solid"
              // borderColor="primary.200"
              alignItems="center"
              justifyContent="center"
              onClick={() => gotoPrev()}
              cursor="pointer"
            >
              <MemoArrow width="5rem" height="5rem" />
            </Flex>

            <Flex
              width="5.6rem"
              height="5.6rem"
              // borderRadius="50%"
              // border="0.2rem solid"
              // borderColor="primary.200"
              // boxShadow="0px 4px 8px rgba(0, 0, 0, 0.4)"
              opacity="0.6"
              alignItems="center"
              justifyContent="center"
              onClick={() => gotoNext()}
              cursor="pointer"
            >
              <Box transform="rotate(180deg)" top="-0.2rem">
                <MemoArrow width="5rem" height="5rem" />
              </Box>
            </Flex>
          </Box>
        </Box>
      </Box>

      {/* tab 3 what next cards */}
      {activeReportId === "2" && (
        <WhatNextCards banners={testBanners?.banners} />
      )}

      {/* Disclaimer */}

      <Flex
        px={{ xs: "3rem", lg: "7rem" }}
        py={{ xs: "1.6rem", lg: "2rem" }}
        bg="grey.500"
        alignItems="center"
        justifyContent="space-between"
      >
        <Text
          fontSize={{ xs: "1.6rem", lg: "2.2rem" }}
          lineHeight={{ xs: "2.4rem", lg: "3.3rem" }}
          fontWeight="600"
          color="white"
          fontFamily="primaryMedium"
        >
          Disclaimer
        </Text>
        <Flex
          width={{ xs: "1.6rem", lg: "2.2rem" }}
          height={{ xs: "1.6rem", lg: "2.2rem" }}
          borderRadius="50%"
          border={{ xs: "0.2rem solid", lg: "0.27rem solid" }}
          className="border-white"
          alignItems="center"
          justifyContent="center"
          cursor="pointer"
          onClick={() => setShowModal("disclaimer")}
        >
          <Text
            fontSize={{ xs: "1.2rem", lg: "1.6rem" }}
            lineHeight={{ xs: "1.5rem", lg: "2rem" }}
            fontWeight="700"
            color="white"
            fontFamily="primaryBlack"
            mt="0.3rem"
          >
            ?
          </Text>
        </Flex>
      </Flex>

      {/* mobile scroll overlay button */}
      {scroll && (
        <Box
          position="fixed"
          width="100%"
          display={{ xs: "block", lg: "none" }}
          bottom="0%"
          // min-height="9.6rem"
          background="linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)"
          backgroundSize="cover"
          pt="7.4rem"
          pb="2.6rem"
          zIndex="5"
        >
          {/* <Box
            width="100%"
            min-height="9.6rem"
            backgroundImage="linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)"
          > */}
          <Button
            variant="primary"
            width="24.6rem"
            // top="7.4rem"
            mx="auto"
            textTransform="capitalize"
            onClick={() =>
              setActiveReportId(btnTitle[parseInt(activeReportId, 10)].to)
            }
          >
            {btnTitle[parseInt(activeReportId, 10)].name}
          </Button>
          {/* </Box> */}
        </Box>
      )}
    </Box>
  );
};
