import * as React from "react";

function ArrowCard(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 14 9" fill="none" {...props}>
      <path d="M1 1l6 6 6-6" stroke="#747474" strokeWidth={2} />
    </svg>
  );
}

const MemoArrowCard = React.memo(ArrowCard);
export default MemoArrowCard;
