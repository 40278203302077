import React from "react";

/**
 * A utility to create a Context "container".
 *
 * The container automatically creates a React Context, and a custom provider
 * for the Context that determines its value from React Hook passed to it.
 *
 * @param useValue React hook that manages context value
 * @param createMemoInputs Only re-render when the values specified change
 */
export function createContainer(useValue, createMemoInputs) {
  const Context = React.createContext({});

  const Provider = (props) => {
    const value = useValue(props);
    // @ts-ignore
    const memoizedValue = createMemoInputs
      ? // createMemoInputs won't change between renders, so this is okay
        value
      : value;
    // createMemoInputs('s');
    // const memoizedValue = value;
    const { children } = props;

    return (
      <Context.Provider value={memoizedValue}>{children}</Context.Provider>
    );
  };

  const useContext = () => React.useContext(Context);

  const exports = {
    useContext,
    Context,
    Provider,
    TestProvider: Provider,
  };

  // TestProvider needs to be reworked entirely.
  // Avoid using there where possible
  if (process.env.NODE_ENV === "test") {
    const TestProvider = (props) => {
      const { children, overrides = {} } = props;

      const value = useValue({
        ...props,
        stateOverrides: overrides.state,
      });
      // @ts-ignore
      const memoizedValue = createMemoInputs
        ? //   ? // createMemoInputs won't change between renders, so this is okay
          //     React.useMemo(() => value, createMemoInputs(value))

          value
        : value;

      const testValue = Object.entries(overrides).reduce((tv, [k, v]) => {
        // eslint-disable-next-line no-param-reassign
        tv[k] = { ...tv[k], ...v };

        return tv;
      }, memoizedValue);

      return <Context.Provider value={testValue}> {children}</Context.Provider>;
    };

    exports.TestProvider = TestProvider;
  }

  return exports;
}
