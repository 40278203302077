import { useCallback, useReducer } from "react";
import CryptoJS from "crypto-js";

import api from "utils/api";
import { createContainer } from "utils/Context";
import { persist, hydrate } from "utils/persist";
import {
  AUTH_TOKEN_COOKIE,
  REMEMBER_ME,
  REFRESH_TOKEN_COOKIE,
} from "utils/api/getTokens";
import { removeCookie } from "utils/cookie";
import { AUTH_ACTIONS } from "./actions";
import { authReducer, initialState } from "./reducers";

export const { useContext: useAuth, Provider: AuthProvider } = createContainer(
  () => {
    const [state, dispatch] = useReducer(authReducer, {
      ...initialState,
      isLoggedIn: !!hydrate(AUTH_TOKEN_COOKIE),
      userData: hydrate("userData"),
    });

    const requestOTP = useCallback(
      /**
       * @param {string} mobile
       */
      async (mobile, rememberMe) => {
        removeCookie(REFRESH_TOKEN_COOKIE);
        dispatch(AUTH_ACTIONS.requestOtp.loading());
        persist(REMEMBER_ME, rememberMe ? "true" : "false");
        try {
          const { data } = await api("/auth/patients/request-otp", {
            method: "post",
            data: { mobile },
          });

          dispatch(AUTH_ACTIONS.requestOtp.success(data.data));
          persist("userData", { ...data.data });
        } catch (e) {
          dispatch(AUTH_ACTIONS.requestOtp.failure(e.message));
        }
      },
      []
    );

    const verifyOtp = useCallback(
      /**
       * @param {string} mobile
       * @param {string} otp
       */
      async (mobile, otp) => {
        dispatch(AUTH_ACTIONS.otpVerify.loading());
        try {
          const { data } = await api("/auth/patients/verify-otp", {
            method: "post",
            data: {
              mobile,
              otp,
            },
          });

          persist(AUTH_TOKEN_COOKIE, data?.data?.token?.token);
          if (hydrate(REMEMBER_ME) === "true") {
            persist(REFRESH_TOKEN_COOKIE, data?.data?.token?.refresh_token);
          }
          persist("userData", { ...data.data });
          dispatch(
            AUTH_ACTIONS.otpVerify.success({
              ...data.data,
            })
          );
          // navigate("/");
        } catch (e) {
          dispatch(AUTH_ACTIONS.otpVerify.failure(e.message));
        }
      },
      []
    );

    const resendOTP = useCallback(
      /**
       * @param {string} mobile
       */
      async (mobile) => {
        dispatch(AUTH_ACTIONS.resendOtp.loading());
        try {
          await api("/auth/patients/resend-otp", {
            method: "post",
            data: { mobile },
          });
          dispatch(AUTH_ACTIONS.resendOtp.success());
          setTimeout(() => {
            dispatch(AUTH_ACTIONS.resendOtp.failure());
          }, 10000);
        } catch (e) {
          dispatch(AUTH_ACTIONS.resendOtp.failure(e.message));
        }
      },
      []
    );

    const logout = useCallback(async () => {
      try {
        await api("/auth/patients/revoke-token", {
          method: "post",
          data: {
            token: state?.userData?.token?.token,
          },
        });
      } catch (e) {
        console.error(e);
      }

      removeCookie(AUTH_TOKEN_COOKIE);
      removeCookie("userData");
      dispatch(AUTH_ACTIONS.logout);
    }, [state]);

    const getPatientReportsData = useCallback(
      /**
       * @param {string} patient
       */
      async (patient) => {
        try {
          const { data } = await api("/patients/fetch-report", {
            method: "post",
            data: {
              barcode: patient?.sampleBarcode,
              testcode: patient?.tests.testcode,
            },
          });

          const IV = data.data.iv;
          const ENC_KEY = patient.patientKey;

          const decrypt = (encrypted) => {
            const decipher = CryptoJS.AES.decrypt(encrypted, ENC_KEY, {
              iv: IV,
            });
            return decipher.toString(CryptoJS.enc.Utf8);
          };
          dispatch(
            AUTH_ACTIONS.patientReport(JSON.parse(decrypt(data.data.report)))
          );
        } catch (e) {
          console.error(e);
        }
      },
      [state]
    );

    // According to the implementation
    // List of all the patients related to a mobile number is fetched
    // in test mode there may be one to many relationship
    // in real mode 95% chances of one to one mapping exists
    // in case of one to many the main patient is choosen by backend algo and returned in VERIFY-OTP api
    const getPatientReportsListing = useCallback(
      /**
       * @param {string} mobile
       */
      async (mobile) => {
        try {
          const { data } = await api("/patients/fetch-listing", {
            method: "post",
            data: {
              mobile,
            },
          });
          dispatch(AUTH_ACTIONS.patientsListing(data.data.patients));
        } catch (e) {
          console.error(e);
        }
      },
      [state]
    );

    // Strappi information related to patient Test
    const getStrappiTests = useCallback(
      /**
       * @param {string} testName
       */
      async (testName) => {
        try {
          const test = await fetch(
            // eslint-disable-next-line prefer-template
            `${process.env.REACT_APP_STRAPI}/tests?` +
              new URLSearchParams({
                key: testName,
              })
          );
          const testdata = await test.json();

          dispatch(AUTH_ACTIONS.reportTests(testdata[0] || {}));
        } catch (e) {
          console.error(e);
        }
      },
      []
    );

    // Strappi information regarding requested disorder
    const getTooltips = useCallback(
      /**
       * @param {string} disorderKey
       */
      async (disorderKey) => {
        try {
          const test = await fetch(
            // eslint-disable-next-line prefer-template
            `${process.env.REACT_APP_STRAPI}/info-icons?` +
              new URLSearchParams({
                key: disorderKey,
              })
          );
          const testdata = await test.json();

          dispatch(AUTH_ACTIONS.reportDisorders(testdata || []));
        } catch (e) {
          console.error(e);
        }
      },
      []
    );

    const getSlugBanners = useCallback(
      /**
       * @param {string} slug
       */
      // eslint-disable-next-line consistent-return
      async (slug) => {
        try {
          const test = await fetch(
            // eslint-disable-next-line prefer-template
            `${process.env.REACT_APP_STRAPI}/test-banners?` +
              new URLSearchParams({
                slug,
              })
          );
          const testdata = await test.json();

          return testdata?.[0];
        } catch (e) {
          console.error(e);
        }
      },
      []
    );

    // Strappi information regarding requested banners
    const getStrappiBanners = useCallback(
      /**
       * @param {string} test_name
       * @param {string} test_window
       * @param {string} test_result
       */
      async (test_name, test_window, test_result) => {
        try {
          const test = await fetch(
            // eslint-disable-next-line prefer-template
            `${process.env.REACT_APP_STRAPI}/banner-rule-engines?` +
              new URLSearchParams({
                test_name,
                test_window,
                test_result,
              })
          );
          const testdata = await test.json();

          Promise.all(
            testdata[0]?.banner_association_key?.map(async (item) => {
              const bannerItem = await getSlugBanners(item?.key);
              return bannerItem;
            })
          )
            .then((value) => {
              dispatch(
                AUTH_ACTIONS.reportBanners({
                  banners: value,
                  // banners: testdata[0]?.test_banners,
                  videoList: testdata[0]?.video_list
                    ? testdata[0]?.video_list?.video_component
                    : [],
                })
              );
            })
            .catch((e) => console.error(e));
        } catch (e) {
          console.error(e);
        }
      },
      []
    );

    // user entered the product using link
    // Link format: "{{url}}/login/:patientId/:barcode"
    const requestOTPbyLinkId = useCallback(
      /**
       * @param {string} patientId
       * @param {string} barcode
       */
      async (patientId, barcode) => {
        dispatch(AUTH_ACTIONS.requestOtp.loading());
        try {
          const { data } = await api("/auth/patients/request-labmate-otp", {
            method: "post",
            data: { patient: patientId, barcode },
          });

          const userData = {
            ...state?.userData,
            patient: { ...data?.data?.localPatientRow },
          };

          dispatch(AUTH_ACTIONS.requestOtp.success(userData));
          persist("userData", userData);
        } catch (e) {
          dispatch(AUTH_ACTIONS.requestOtp.failure(e.message));
        }
      },
      []
    );

    const ratingsStrappi = useCallback(
      async (emojiType, emojiScore, emojiDate, patientData) => {
        try {
          const requestData = {
            emoji_type: emojiType,
            emoji_score: emojiScore?.toString(),
            emoji_date: emojiDate,
            patient_data: JSON?.stringify(patientData),
          };
          await fetch(`${process.env.REACT_APP_STRAPI}/emoji-responses`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestData),
          });

          dispatch(AUTH_ACTIONS.ratingsRecieved);
        } catch (e) {
          console.error(e);
        }
      },
      []
    );

    // Strappi information regarding requested banners
    const getStrappiScanDetails = useCallback(async () => {
      try {
        const test = await fetch(
          `${process.env.REACT_APP_STRAPI}/scan-details`
        );
        const testdata = await test?.json();

        testdata?.sort((a, b) => (a?.id < b.id ? -1 : 1));

        return testdata;
      } catch (e) {
        console.error(e);
        return [];
      }
    }, []);

    const getStrappiHeadings = useCallback(async () => {
      try {
        const test = await fetch(`${process.env.REACT_APP_STRAPI}/headings`);
        const testdata = await test?.json();

        dispatch(AUTH_ACTIONS.strappiHeadings(testdata));
      } catch (e) {
        console.error(e);
      }
    });

    return {
      state,
      actions: {
        requestOTP,
        verifyOtp,
        resendOTP,
        logout,
        getPatientReportsData,
        getPatientReportsListing,
        getStrappiTests,
        getTooltips,
        getStrappiBanners,
        requestOTPbyLinkId,
        ratingsStrappi,
        getStrappiScanDetails,
        getStrappiHeadings,
      },
    };
  }
);
