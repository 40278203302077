import React from "react";

import { Box, Heading, Grid, Image, Text, Flex } from "atoms";

// const list = [
//   "Hormonal values from the pregnancy measured on Fetal Medicine foundation (UK) accredited analyzers and reagents",
//   "Robust indigenous medians from over 5 lac+ pregnancies for different gestation ages",
//   "Risk calculations from evidence based algorithms validated through large international studies ",
//   "External audit of the prenatal screening program by United Kingdom National External Quality Assessment Service (UKNEQAS) scheme and Randox International Quality Assessment Scheme (RIQAS)",
// ];

export const AboutTest = ({
  heading,
  testingWindow,
  sampleType,
  description,
  logoUrl,
}) => {
  const formattedTestingWindow = testingWindow?.split("-")[0] || "";

  const data = [
    {
      id: "0",
      title: "Test Name",
      info: heading,
      bold: true,
    },
    {
      imageElement: "imageElement",
    },
    {
      id: "1",
      title: "Testing Window",
      info: formattedTestingWindow,
    },
    {
      id: "2",
      title: "Sample Type",
      info: sampleType,
    },
  ];

  return (
    <Box>
      <Heading
        // name="About Evico Duo"
        name={`About ${heading}`}
        fontSize={{ xs: "1.8rem", lg: "2.5rem" }}
        lineHeight={{ xs: "2.9rem", lg: "4rem" }}
      />

      <Flex alignItems="center">
        <Box
          width={{ xs: "15.5rem", lg: "31rem" }}
          height={{ xs: "4.8rem", lg: "9.6rem" }}
          display={{ xs: "none", lg: "block" }}
        >
          <Image src={logoUrl || ""} alt="report-img" />
        </Box>
        <Grid
          mt={{ xs: "3.8rem", lg: "4.2rem" }}
          gridAutoColumns={{
            xs: "minmax(13rem, auto)",
            lg: "minmax(28.2rem, auto)",
          }}
          gridTemplateColumns={{ xs: "1fr 1fr", lg: "unset" }}
          gridAutoFlow={{ lg: "column" }}
          gridColumnGap={{ xs: "6.9rem", lg: "2.2rem" }}
          gridRowGap={{ xs: "1.7rem", lg: "0" }}
        >
          {/* <Box
            width={{ xs: "15.5rem", lg: "31rem" }}
            height={{ xs: "4.8rem", lg: "9.6rem" }}
            display={{ xs: "none", lg: "block" }}
          >
            <Image src={logoUrl || ""} alt="report-img" />
          </Box> */}
          {data?.map((item, index) =>
            index === 1 ? (
              <Box
                width={{ xs: "15.5rem", lg: "31rem" }}
                height={{ xs: "4.8rem", lg: "9.6rem" }}
                display={{ xs: "block", lg: "none" }}
                left="-2.4rem"
                // eslint-disable-next-line react/no-array-index-key
                key={index}
              >
                <Image src={logoUrl || ""} alt="report-img" />
              </Box>
            ) : (
              <Box
                // width={{ xs: "13rem", lg: "28.2rem" }}
                border={{ lg: "0.3rem solid" }}
                borderColor="primary.500"
                borderRadius="1rem"
                px={{ lg: "3.8rem" }}
                py={{ lg: "1.8rem" }}
                // eslint-disable-next-line react/no-array-index-key
                key={index}
              >
                <Text
                  fontSize={{ xs: "1.4rem", lg: "2rem" }}
                  fontWeight="600"
                  lineHeight={{ xs: "2.1rem", lg: "3rem" }}
                  fontFamily="primaryRegular"
                  color="primary.200"
                >
                  {item?.title}
                </Text>
                <Text
                  fontSize={
                    item.bold
                      ? { xs: "2rem", lg: "3rem" }
                      : { xs: "1.6rem", lg: "2rem" }
                  }
                  fontWeight={item?.bold ? "500" : "400"}
                  lineHeight={
                    item.bold
                      ? { xs: "3rem", lg: "4.5rem" }
                      : { xs: "2.4rem", lg: "3rem" }
                  }
                  fontFamily={item?.bold ? "primaryMedium" : "primaryRegular"}
                  color="grey.900"
                  mt={item?.bold ? "0" : "0.5rem"}
                >
                  {item?.info}
                </Text>
              </Box>
            )
          )}
        </Grid>
      </Flex>

      <Box
        maxWidth={{ xs: "100%", lg: "107rem" }}
        color="grey.900"
        fontSize="1.6rem"
        fontWeight={{ xs: "300", lg: "500" }}
        lineHeight="3rem"
        fontFamily={{ xs: "primaryLight", lg: "primaryMedium" }}
        mt={{ xs: "2.5rem", lg: "6rem" }}
        letterSpacing="0.07em"
      >
        {/* <Text>
          Evico Screen is an evidence based prenatal screening program curated
          by Lilac Insights in accordance with the Fetal Medicine Foundation
          (UK) guidelines for First Trimester Screening to determine the
          probability of most common chromosomal aneuploidies in a pregnancy. It
          utilizes:
        </Text>
        {list.map((item, index) => (
          <Flex
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            ml="0.2rem"
            alignItems="start"
            justifyContent="start"
          >
            <Box
              position="absolute"
              width={{ xs: "0.4rem", lg: "0.6rem" }}
              height={{ xs: "0.4rem", lg: "0.6rem" }}
              borderRadius="50%"
              bg="grey.900"
              mt={{ xs: "1rem", lg: "0.9rem" }}
            />
            <Text ml="1rem">{item}</Text>
          </Flex>
        ))} */}
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: description || "" }}
        />
      </Box>
    </Box>
  );
};
