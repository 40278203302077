import React, { useCallback, useEffect, useState } from "react";

import useAuth from "contexts/Auth";
import { Box, Heading, Grid, Text, Flex } from "atoms";
import { InfoModal } from "molecules";
import { customArrayOfObjectsFromJson } from "utils/utilities";

const HEADINGS = ["Test Name", "Conc.", "Unit", "Corr. Mom"];

// const modalInfo =
//   "A medical term for having an extra copy of a chromosome is ‘trisomy.’ Down syndrome is also referred to as Trisomy 21. This extra copy changes how the baby’s body and brain develop, which can cause both mental and physical challenges for the baby. <br/> A medical term for having an extra copy of a chromosome is ‘trisomy.’ Down syndrome is also referred to as Trisomy 21. This extra copy changes how the baby’s body and brain develop, which can cause both mental and physical challenges for the baby. <br/> A medical term for having an extra copy of a chromosome is ‘trisomy.’ Down syndrome is also referred to as Trisomy 21. This extra copy changes how the baby’s body and brain develop, which can cause both mental and physical challenges for the baby. <br/> A medical term for having an extra copy of a chromosome is ‘trisomy.’ Down syndrome is also referred to as Trisomy 21. This extra copy changes how the baby’s body and brain develop, which can cause both mental and physical challenges for the baby.";

export const SpecimenDetails = ({ scanDetails, scanUnits, MOM, headings }) => {
  const [showModal, setShowModal] = useState(false);

  const [modifiedSpecimen, setModifiedSpecimen] = useState(undefined);
  const [scanDetailsStrappi, setScanDetailsStrappi] = useState([]);
  const [data, setData] = useState([]);

  const specimenHeading = headings?.heading_component?.find(
    (item) => item.key === "specimenDetails"
  );

  const {
    state: { testDisorders },
    actions: { getStrappiScanDetails },
  } = useAuth();

  const getDisorderName = useCallback(
    (key) => {
      const selectedDisorder = testDisorders?.find(
        (disorder) => disorder?.key === key
      );

      return selectedDisorder?.name || "";
    },
    [testDisorders]
  );

  const getDisorderTooltip = useCallback(
    (key) => {
      const selectedDisorder = testDisorders?.find(
        (disorder) => disorder?.key === key
      );

      return selectedDisorder?.description || "";
    },
    [testDisorders]
  );

  useEffect(() => {
    setModifiedSpecimen(
      customArrayOfObjectsFromJson({
        value: MOM,
        containKey: "color",
        externalMap: {
          externalPropertyName: [
            { name: "concentration" },
            { name: "unit" },
            { name: "name", comparisonKey: "name" },
          ],
          externalObject: [scanDetails, scanUnits, MOM],
        },
      })
    );
  }, [MOM, scanDetails, scanUnits]);

  const loadScanDetailsStrappi = useCallback(async () => {
    const dataSet = await getStrappiScanDetails();
    setScanDetailsStrappi(dataSet);
  }, [getStrappiScanDetails]);
  useEffect(() => {
    loadScanDetailsStrappi();
  }, [loadScanDetailsStrappi]);

  useEffect(() => {
    const unitKeys = {};
    scanDetails &&
      scanDetailsStrappi?.forEach((record) => {
        unitKeys[record?.key] = scanDetails[record?.key]?.toString();
      });
    const unitSet =
      scanDetails &&
      customArrayOfObjectsFromJson({
        value: unitKeys,
        containKey: " ",
        externalMap: {
          externalPropertyName: [{ name: "unit" }],
          externalObject: [scanUnits],
        },
      });

    const dataSet = [];

    scanDetails &&
      scanDetailsStrappi?.forEach((record) => {
        if (scanDetails[record?.key]?.toString() ?? null) {
          const dataItem = {
            title: record?.name,
            description: `${scanDetails[record?.key]?.toString()} ${
              unitSet?.find(
                (value) =>
                  value?.name?.includes(record?.key) ||
                  value?.name?.includes(record?.key?.replace(/[^a-zA-Z]+/g, ""))
              )?.prop?.unit || ""
            }`,
            ...(record?.tooltipKey && record?.tooltipKey !== " "
              ? { tooltipKey: record?.tooltipKey, hasInfo: true }
              : {}),
          };
          dataSet.push(dataItem);
        }
      });

    setData(dataSet);
  }, [scanDetailsStrappi, scanDetails]);

  return (
    <Box>
      {showModal && (
        <InfoModal
          isOpen={showModal}
          onRequestClose={() => setShowModal(false)}
          ariaHideApp={false}
          title={getDisorderName(showModal)}
          info={getDisorderTooltip(showModal)}
          height={{ xs: "35rem" }}
          keyTooltip={showModal}
        />
      )}
      {specimenHeading && (
        <Flex alignItems="center">
          <Heading
            name={specimenHeading?.title}
            fontSize={{ xs: "1.8rem", lg: "2.5rem" }}
            lineHeight={{ xs: "2.9rem", lg: "4rem" }}
          />
          {specimenHeading?.tooltipKey && (
            <Box
              display="flex"
              width="1.6rem"
              height="1.6rem"
              borderRadius="50%"
              border="0.2rem solid"
              borderColor="accent.900"
              alignItems="center"
              justifyContent="center"
              cursor="pointer"
              onClick={() => setShowModal(specimenHeading?.tooltipKey)}
              ml="0.3rem"
            >
              <Text
                fontSize="1.2rem"
                lineHeight="1.5rem"
                fontWeight="700"
                color="accent.900"
                mt="0.3rem"
              >
                ?
              </Text>
            </Box>
          )}
        </Flex>
      )}
      <Box>
        <Grid
          gridTemplateColumns={{ xs: "1fr", lg: "1fr 1.5fr" }}
          gridColumnGap="4.3rem"
          gridRowGap="4.9rem"
          mt={{ xs: "3.5rem", lg: "6.8rem" }}
          mb={{ lg: "6.8rem" }}
        >
          <Box>
            <Grid
              py={{ xs: "1rem", lg: "2.1rem" }}
              boxShadow="0px 0px 4px 0px rgba(0, 0, 0, 0.25)"
              borderRadius="0.4rem"
            >
              {data?.map((item, index) => {
                return (
                  <Grid
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    gridTemplateColumns="1.5fr 0.8fr"
                    bg={index % 2 === 0 ? "purple.100" : "white"}
                  >
                    <Box display="flex" alignItems="center">
                      <Text
                        fontWeight="400"
                        fontFamily="primaryRegular"
                        fontSize={{ xs: "1.4rem", lg: "2.2rem" }}
                        lineHeight="2.4rem"
                        color="primary.500"
                        py={{ xs: "0.5rem", lg: "1.5rem" }}
                        pl={{ xs: "2.5rem", lg: "3.6rem" }}
                      >
                        {item.title}
                      </Text>
                      {item.hasInfo && (
                        <Flex
                          width={{ xs: "0.9rem", lg: "1.3rem" }}
                          height={{ xs: "0.9rem", lg: "1.3rem" }}
                          ml={{ xs: "0.7rem", lg: "1.4rem" }}
                          mt={{ xs: "-0.5rem", lg: "-0.6rem" }}
                          border={{ xs: "0.11rem solid", lg: "0.16rem solid" }}
                          borderColor={{ xs: "accent.900", lg: "accent.900" }}
                          borderRadius="50%"
                          alignItems="center"
                          justifyContent="center"
                          cursor="pointer"
                          onClick={() => setShowModal(item?.tooltipKey)}
                        >
                          <Text
                            fontSize={{ xs: "0.7rem", lg: "1rem" }}
                            lineHeight={{ xs: "0.9rem", lg: "1.2rem" }}
                            color="accent.900"
                            fontWeight="700"
                            fontFamily="primaryBlack"
                            mt={{ xs: "0.15rem", lg: "0.2rem" }}
                          >
                            ?
                          </Text>
                        </Flex>
                      )}
                    </Box>
                    <Box>
                      <Text
                        fontFamily="primaryRegular"
                        fontWeight={{ xs: "300", lg: "400" }}
                        fontSize={{ xs: "1.4rem", lg: "1.6rem" }}
                        lineHeight="2.4rem"
                        py={{ xs: "0.5rem", lg: "1.5rem" }}
                        color="grey.900"
                      >
                        {item.description}
                      </Text>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Box>

          <Box
            boxShadow="0px 0px 4px 0px rgba(0, 0, 0, 0.25)"
            borderRadius="0.4rem"
          >
            <Grid gridTemplateColumns="1fr 0.6fr 0.7fr 0.9fr">
              {HEADINGS.map((item, index) => (
                <Text
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  fontWeight={{ xs: "500", lg: "600" }}
                  fontSize={{ xs: "1.4rem", lg: "2.2rem" }}
                  lineHeight={{ xs: "2.2rem", lg: "3.5rem" }}
                  fontFamily="primaryMedium"
                  color="secondary.900"
                  textAlign={index === 0 ? "start" : "center"}
                  pl={index === 0 && { xs: "1.1rem", lg: "3.3rem" }}
                  pt={{ xs: "0.9rem", lg: "1.5rem" }}
                  pb={{ xs: "0.1rem", lg: "0.4rem" }}
                >
                  {item}
                </Text>
              ))}
            </Grid>
            <Box>
              {modifiedSpecimen &&
                Array.isArray(modifiedSpecimen) &&
                modifiedSpecimen?.map((item, index) => (
                  <Grid
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    gridTemplateColumns="1fr 0.6fr 0.7fr 0.9fr"
                    bg="green.100"
                    mt={{ xs: "0.8rem", lg: "1.1rem" }}
                    letterSpacing="0.07rem"
                  >
                    <Flex alignItems="center">
                      <Text
                        fontWeight="400"
                        fontSize={{ xs: "1.4rem", lg: "2rem" }}
                        lineHeight={{ xs: "1.4rem", lg: "4.2rem" }}
                        color="grey.900"
                        fontFamily="primaryRegular"
                        pl={{ xs: "1.1rem", lg: "3.3rem" }}
                        py={{ xs: "1.4rem", lg: "1.3rem" }}
                      >
                        {item?.prop?.name}
                      </Text>
                      <Flex
                        width={{ xs: "0.9rem", lg: "1.3rem" }}
                        height={{ xs: "0.9rem", lg: "1.3rem" }}
                        ml={{ xs: "0.7rem", lg: "1.4rem" }}
                        mt={{ xs: "-0.5rem", lg: "-0.6rem" }}
                        border={{ xs: "0.11rem solid", lg: "0.16rem solid" }}
                        borderColor={{ xs: "accent.900", lg: "accent.900" }}
                        borderRadius="50%"
                        alignItems="center"
                        justifyContent="center"
                        cursor="pointer"
                        onClick={() => setShowModal(item?.name)}
                      >
                        <Text
                          fontSize={{ xs: "0.7rem", lg: "1rem" }}
                          lineHeight={{ xs: "0.9rem", lg: "1.2rem" }}
                          color="accent.900"
                          fontWeight="700"
                          fontFamily="primaryBlack"
                          mt={{ xs: "0.15rem", lg: "0.2rem" }}
                        >
                          ?
                        </Text>
                      </Flex>
                    </Flex>

                    <Box
                      bg={
                        item?.prop?.concentration === "Present"
                          ? "green.400"
                          : item?.prop?.concentration === "Absent"
                          ? "accent.500"
                          : "lightgrey.100"
                      }
                      py={{ xs: "1.4rem", lg: "1.3rem" }}
                    >
                      <Text
                        fontWeight="400"
                        fontFamily="primaryRegular"
                        fontSize={{ xs: "1.4rem", lg: "2rem" }}
                        lineHeight={{ xs: "1.4rem", lg: "4.2rem" }}
                        color={
                          item?.prop?.concentration === "Present"
                            ? "green.900"
                            : item?.prop?.concentration === "Absent"
                            ? "accent.100"
                            : "grey.900"
                        }
                        textAlign="center"
                      >
                        {item?.prop?.concentration || "-"}
                      </Text>
                    </Box>
                    <Text
                      fontWeight={{ xs: "300", lg: "400" }}
                      fontFamily="primaryRegular"
                      fontSize={{ xs: "1.4rem", lg: "1.8rem" }}
                      lineHeight={{ xs: "1.4rem", lg: "4.2rem" }}
                      color="grey.900"
                      textAlign="center"
                      py={{ xs: "1.4rem", lg: "1.3rem" }}
                    >
                      {item?.prop?.unit || "-"}
                    </Text>
                    <Box
                      bg={item?.value && "green.400"}
                      py={{ xs: "1.4rem", lg: "1.3rem" }}
                    >
                      <Text
                        fontWeight={{ xs: "300", lg: "400" }}
                        fontFamily="primaryRegular"
                        fontSize={{ xs: "1.4rem", lg: "1.8rem" }}
                        lineHeight={{ xs: "1.4rem", lg: "4.2rem" }}
                        color={item?.value ? "green.900" : "grey.900"}
                        textAlign="center"
                      >
                        {item?.value || "-"}
                      </Text>
                    </Box>
                  </Grid>
                ))}
            </Box>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};
