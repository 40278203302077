import React, { useCallback, useEffect } from "react";

import useAuth from "contexts/Auth";
import { ReportPage } from "templates";
// import { customArrayOfObjectsFromJson } from "utils/utilities";

export const Reports = () => {
  const {
    state: { userData, patients, report },
    actions: {
      getPatientReportsData,
      getPatientReportsListing,
      getStrappiTests,
      getTooltips,
      getStrappiBanners,
      getStrappiHeadings,
    },
  } = useAuth();

  useEffect(() => {
    getPatientReportsListing(userData.patient.mobile);
    getStrappiHeadings();
  }, []);

  useEffect(() => {
    if (patients) {
      const patient = patients.findIndex(
        (val) => val.patientId === userData.patient.limp_patient_id
      );
      getPatientReportsData(patients[patient]);
    }
  }, [patients]);

  const fetchReportRequests = useCallback(() => {
    if (report) {
      // test info
      getStrappiTests(report?.additionalInfo?.testName);
      // banners
      getStrappiBanners(
        report?.additionalInfo?.testName,
        report?.additionalInfo?.testICDCode,
        report?.overallRiskValue
      );
      // tooltips
      Object.keys(report?.risks || {})?.forEach((riskItem) => {
        getTooltips(riskItem);
      });

      // customArrayOfObjectsFromJson({
      //   value: report?.testResultMom,
      //   containKey: "color",
      // })?.forEach(({ name: momItem }) => {
      //   getTooltips(momItem);
      // });
    }
  }, [report, getTooltips, getStrappiBanners, getStrappiTests]);

  useEffect(() => {
    fetchReportRequests();
  }, [report]);

  return (
    <>
      <ReportPage />
    </>
  );
};

export default Reports;
