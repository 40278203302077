import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { IntlProvider } from "react-intl";
import { ThemeProvider } from "styled-components";
import { theme } from "styles";
import translations from "translations";
import { AuthProvider } from "contexts/Auth";
import { AuthGate } from "./pages/Auth/AuthGate";
import "styles/globals.css";
import "styles/style.css";

// import { Layout } from "templates";

function App() {
  const lang = "en";
  return (
    <ThemeProvider theme={theme}>
      <IntlProvider locale={lang} messages={translations[lang]}>
        {/* <Layout> */}
        {/* <Component {...pageProps} /> */}
        {/* </Layout> */}
        <Router>
          <AuthProvider>
            <AuthGate />
          </AuthProvider>
        </Router>
      </IntlProvider>
    </ThemeProvider>
  );
}

export default App;
