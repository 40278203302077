import React from "react";

import { AuthenticatedRoutes, UnauthenticatedRoutes } from "routes/Routes";
import { useAuth } from "contexts/Auth";
import GA from "utils/GoogleAnalytics";
import { useLocation } from "react-router-dom";

export const AuthGate = () => {
  const {
    state: { isLoggedIn },
  } = useAuth();

  const location = useLocation();

  return (
    <>
      {GA.init() && <GA.RouteTracker location={location} />}
      {isLoggedIn ? <AuthenticatedRoutes /> : <UnauthenticatedRoutes />}
    </>
  );
};
