import React, { Suspense } from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import { Loader } from "atoms";

// import { Loader } from "../atoms/Loader/Loader";
import { Layout } from "templates";
import { ReportProvider } from "utils/Contexts";
import Login from "pages/login";
import Home from "pages/home";
import Reports from "pages/reports";
import ErrorPage from "pages/ErrorPage";
import Logout from "pages/Auth/Logout";

// const AdminDashboard = React.lazy(
//   () => import(/* webpackChunkName: 'AdminDashboard' */ "pages/Admin"),
// );

export const UnauthenticatedRoutes = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        {/* <Route path="login" exact element={<Login />} /> */}
        <Route path="login" exact element={<Outlet />}>
          <Route index element={<Login />} />
          <Route path=":patientId/:barcode" element={<Login />} />
        </Route>
        {/* <Route path="login" exact element={<Login />}>
          <Route path="register" element={<RegisterUser />} />
          <Route path="otp-verify" element={<VerifyOtp />} />
          <Route path="verified" element={<RegisterUser />} />
        </Route> */}
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Suspense>
  );
};

export const AuthenticatedRoutes = () => {
  return (
    <Routes>
      <Route path="login" exact element={<Outlet />}>
        <Route index element={<Login />} />
        <Route path=":patientId/:barcode" element={<Login />} />
      </Route>
      <Route
        path="*"
        element={
          <ReportProvider>
            <Layout>
              <Suspense fallback={<Loader />}>
                <Routes>
                  <Route path="logout" exact element={<Logout />} />
                  <Route path="reports" exact element={<Reports />} />
                  <Route path="/" element={<Home />} />
                  <Route path="*" element={<ErrorPage />} />
                  {/* <Redirect to="/" /> */}
                </Routes>
              </Suspense>
            </Layout>
          </ReportProvider>
        }
      />
    </Routes>
  );
};
