import { ServerException } from "exceptions";
import axios from "axios";

import { persist, hydrate } from "utils/persist";
import { RESPONSE } from "utils/constants";
import { loadCookie } from "utils/cookie";

// import { AUTH_TOKEN_COOKIE, REMEMBER_ME } from "./getTokens";
import { makeRequestInit } from "./makeRequestInit";
import {
  AUTH_TOKEN_COOKIE,
  REFRESH_TOKEN_COOKIE,
  REMEMBER_ME,
} from "./getTokens";

let loading = false;
const delay = (ms) => new Promise((res) => setTimeout(res, ms));
export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  timeout: 30000,
});

export const api = async (relativeUri, options = {}) => {
  const init = makeRequestInit(options);
  try {
    const response = await axiosInstance.request({
      ...init,
      url: relativeUri,
    });

    if (
      response.data?.success === 1
      // typeof response.data === "string"
    ) {
      return response;
    }
    // eslint-disable-next-line no-throw-literal
    throw { response };
  } catch (error) {
    if (error.response && error.response.status === 500) {
      throw new ServerException({
        message: "Something went wrong.",
      });
    }

    // refresh token stuff
    if (error.response && error.response?.statusText === RESPONSE.expired) {
      if (
        hydrate(REMEMBER_ME) === "false" ||
        !hydrate(AUTH_TOKEN_COOKIE, "cookies")
      ) {
        window.location.href = "/logout";
      } else {
        let waited = false;
        while (loading) {
          waited = true;
          // eslint-disable-next-line no-await-in-loop
          await delay(1000);
        }
        if (hydrate(AUTH_TOKEN_COOKIE, "cookies") !== null && !waited) {
          loading = true;
          const response = await axios(
            `${process.env.REACT_APP_API_HOST}/auth/patients/refresh-token`,
            {
              method: "post",
              // headers: {
              //   authorization: `Bearer ${hydrate(AUTH_TOKEN_COOKIE, "cookies")}`,
              // },
              data: {
                refreshToken: hydrate(REFRESH_TOKEN_COOKIE, "cookies"),
              },
            }
          )
            .then(async ({ data }) => {
              if (data?.success === 0) {
                window.location.href = "/logout";
              } else {
                persist(AUTH_TOKEN_COOKIE, data?.data?.token?.token);
                if (loadCookie(REMEMBER_ME) === "true") {
                  persist(
                    REFRESH_TOKEN_COOKIE,
                    data?.data?.token?.refresh_token
                  );
                }
                return api(relativeUri, options);
              }

              return undefined;
            })
            .catch(() => {
              window.location.href = "/logout";
            })
            .finally(() => {
              loading = false;
            });
          return response;
        }
        if (waited) {
          const response = await api(relativeUri, options);
          return response;
        }
      }
    }

    if (error.response && error.response.status === 400) {
      throw new ServerException({ message: error?.response?.data?.errors });
    }

    if (error.response && error.response.status === 404) {
      throw new ServerException({ message: error?.response?.data?.errors });
    }

    throw new ServerException({
      message: error?.response?.data?.message,
    });
  }
};
