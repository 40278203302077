import React, { useCallback, useEffect, useState } from "react";
import Markdown from "markdown-to-jsx";

import { Box, Grid, Flex, Text, Image } from "atoms";
import MemoLogoWhite from "assets/icons/LogoWhite";
// import MemoLocation from "assets/icons/Location";
// import MemoPhone from "assets/icons/Phone";
// import MemoMail from "assets/icons/Mail";
// import MemoFacebookIcon from "assets/icons/FacebookIcon";
// import MemoTwitterIcon from "assets/icons/TwitterIcon";
// import MemoLinkedinIcon from "assets/icons/LinkedinIcon";

// const data = [
//   {
//     id: "1",
//     title: "Contact",
//     info: "+91-22 4184 1438",
//     icon: <MemoPhone width="1.7rem" height="1.7rem" />,
//   },
//   {
//     id: "2",
//     title: "email",
//     info: "contact@lilacinsights.com",
//     icon: <MemoMail width="1.4rem" height="1.1rem" />,
//   },
// ];

export const Footer = () => {
  const [footer, setFooter] = useState({});
  const [data, setData] = useState([]);

  const fetchFooter = useCallback(async () => {
    const test = await fetch(`${process.env.REACT_APP_STRAPI}/footer`);
    const testdata = await test?.json();
    setFooter(testdata);
    setData(
      testdata?.footer_component
        ?.filter((item) => item.title !== "ADDRESS")
        ?.map((item) => {
          return {
            title: item?.title,
            info: item?.description,
            icon: item?.logo?.url,
            // icon: <MemoPhone width="1.7rem" height="1.7rem" />,
          };
        })
    );
  }, []);

  useEffect(() => {
    fetchFooter();
  }, [fetchFooter]);
  return (
    <Grid
      maxWidth={{ xs: "100vw", lg: "144rem" }}
      pt={{ xs: "2.8rem", lg: "3.9rem" }}
      pb={{ xs: "2.6rem", lg: "0" }}
      px={{ lg: "6.6rem" }}
      gridTemplateColumns={{ lg: "1fr 1fr 1fr" }}
      backgroundImage="linear-gradient(101.79deg, #662C72 -11.75%, #AE7FB8 111.02%)"
      boxShadow="0px 4px 8px rgba(0, 0, 0, 0.16)"
      color="white"
      zIndex="10"
    >
      <Box>
        <Box
          width={{ xs: "18.5rem", lg: "24.6rem" }}
          height={{ xs: "6rem", lg: "8rem" }}
          ml={{ xs: "2.9rem", lg: "0" }}
        >
          <MemoLogoWhite width="100%" height="100%" />
        </Box>
        <Flex mt={{ xs: "2.1rem", lg: "2rem" }} alignItems="center">
          <Box width={{ xs: "35%", lg: "6.4rem" }} height="0.1rem" bg="white" />
          <Grid
            gridTemplateColumns="1fr 1fr 1fr"
            gridColumnGap={{ xs: "1.5rem", lg: "0.7rem" }}
            mx={{ xs: "1.4rem", lg: "3rem" }}
          >
            {footer?.social_media_icon?.map((item, index) => {
              return (
                item?.link && (
                  // eslint-disable-next-line react/no-array-index-key
                  <a href={item?.link} key={index}>
                    <Box>
                      <Image src={item?.logo?.url} />
                    </Box>
                  </a>
                )
              );
            })}
            {/* <MemoFacebookIcon />
            <MemoTwitterIcon />
            <MemoLinkedinIcon /> */}
          </Grid>
          <Box
            width={{ xs: "100%", lg: "6.4rem" }}
            height="0.1rem"
            bg="white"
          />
        </Flex>
      </Box>

      <Box mt={{ xs: "6.1rem", lg: "1.6rem" }} px={{ xs: "4rem", lg: "0" }}>
        <Flex alignItems="start">
          <Box mr="1.5rem" width="2rem" height="2rem" mt="0.4rem">
            <Image
              src={
                footer?.footer_component?.find(
                  (item) => item.title === "ADDRESS"
                )?.logo?.url
              }
              alt="Back"
              height="1.7rem"
              width="1.7rem"
            />
          </Box>
          <Box>
            <Text
              fontSize="2rem"
              fontWeight="500"
              color="white"
              lineHeight="3.2rem"
              fontFamily="primaryMedium"
              textTransform="uppercase"
            >
              {
                footer?.footer_component?.find(
                  (item) => item.title === "ADDRESS"
                )?.title
              }
            </Text>
            <Text
              maxWidth="34.1rem"
              fontSize="1.6rem"
              fontWeight="300"
              color="white"
              lineHeight="2.7rem"
              fontFamily="primaryLight"
            >
              <Markdown
                options={{
                  overrides: {
                    p: {
                      props: {
                        style: {
                          color: `white`,
                        },
                      },
                    },
                    span: {
                      props: {
                        style: {
                          color: `white`,
                        },
                      },
                    },
                  },
                }}
              >
                {footer?.footer_component?.find(
                  (item) => item.title === "ADDRESS"
                )?.description || " "}
              </Markdown>
            </Text>
          </Box>
        </Flex>
      </Box>

      <Box mt={{ xs: "3.8rem", lg: "1.6rem" }} ml={{ xs: "4rem", lg: "auto" }}>
        {data?.map((item, index) => (
          <Flex
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            alignItems="start"
            mb={{ xs: "2.5rem", lg: "5rem" }}
          >
            <Box mr="1.4rem" mt={{ xs: "0.8rem", lg: "0.6rem" }}>
              {item?.icon && (
                <Image
                  src={item?.icon}
                  alt="Back"
                  height="1.7rem"
                  width="1.7rem"
                />
              )}
            </Box>
            <Box>
              <Text
                fontSize="2rem"
                fontWeight="500"
                color="white"
                lineHeight="3.2rem"
                fontFamily="primaryMedium"
                textTransform="uppercase"
              >
                {item?.title}
              </Text>
              <Text
                fontSize="1.6rem"
                fontWeight="300"
                color="white"
                lineHeight="2.7rem"
                fontFamily="primaryLight"
              >
                <Markdown
                  options={{
                    overrides: {
                      p: {
                        props: {
                          style: {
                            color: `white`,
                          },
                        },
                      },
                      span: {
                        props: {
                          style: {
                            color: `white`,
                          },
                        },
                      },
                    },
                  }}
                >
                  {item?.info || " "}
                </Markdown>
              </Text>
            </Box>
          </Flex>
        ))}
      </Box>
    </Grid>
  );
};
