import React, { useCallback, useEffect, useState } from "react";

import { Box, Flex, Text, Image, Button } from "atoms";
import { BookCard, VideoModal } from "molecules";
import { isMobile } from "utils/utilities";

export const WhatNextCards = ({ banners }) => {
  const [showVideo, setShowVideo] = useState(false);

  const [bookNowBanner, setBookNowBanner] = useState(null);
  const fetchHomePage = useCallback(async () => {
    try {
      const test = await fetch(
        // eslint-disable-next-line prefer-template
        `${process.env.REACT_APP_STRAPI}/pages?slug=book-now`
      );
      const testdata = await test.json();

      setBookNowBanner(testdata[0] || null);
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    fetchHomePage();
  }, [fetchHomePage]);

  return (
    <Box>
      {showVideo && (
        <VideoModal
          isOpen={showVideo}
          onRequestClose={() => setShowVideo(false)}
          ariaHideApp={false}
          title="What is Down syndrome?"
          thumbnail="/images/thumbnail.png"
          videoUrl="https://www.youtube.com/watch?v=ysz5S6PUM-U"
        />
      )}
      {banners && (
        <Box display={{ xs: "none", lg: "block" }} gridRowGap="2rem">
          {banners?.map((bannerObject, index) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <Box mt="7.5rem" key={index}>
                <BookCard
                  gradient={index % 2 ? "white" : "primary"}
                  title={bannerObject?.banner?.test_title}
                  info={bannerObject?.banner?.test_description}
                  btnText="Book a NIPS/NIPT test"
                  img={bannerObject?.banner?.bg_desktop?.url}
                  btnPrimary={bannerObject?.banner?.button}
                  readMore={bannerObject?.banner?.read_more}
                  watchVideo={bannerObject?.banner?.watch_video}
                  btnLink="https://www.lilacinsights.com/"
                />
              </Box>
            );
          })}
          {/* <BookCard
          gradient="primary"
          title="Non Invasive Prenatal Test (NIPT/NIPS)"
          info="bInsighT, InsighT-Adv & InsighT Plus are Non-Invasive Prenatal Screening tests (NIPT Test) also called cell-free fetal DNA (cffDNA) tests offered exclusively by Lilac Insights. <br/> NIPT test is the screening test for common chromosomal disorders with an accuracy or detection rate of >99% for Trisomy 21, Trisomy"
          btnText="Book a NIPS/NIPT test"
          img="/images/book-card-1.png"
          btnLink="https://www.lilacinsights.com/"
        />
        <Box mt="7.5rem">
          <BookCard
            gradient="white"
            title="Non Invasive Prenatal Test (NIPT/NIPS)"
            info="bInsighT, InsighT-Adv & InsighT Plus are Non-Invasive Prenatal Screening tests (NIPT Test) also called cell-free fetal DNA (cffDNA) tests offered exclusively by Lilac Insights. <br/> NIPT test is the screening test for common chromosomal disorders with an accuracy or detection rate of >99% for Trisomy 21, Trisomy"
            btnText="Book a Prenatal diagnostic test"
            img="/images/book-card-2.png"
            btnLink="https://www.lilacinsights.com/"
          />
        </Box> */}
        </Box>
      )}
      {banners && (
        <Box display={{ xs: "block", lg: "none" }}>
          {/* with image */}
          {/* <Box mt="4.2rem" width="100%" height="16.1rem">
          <Image
            position="absolute"
            src="/images/what-next-card-bg-2.png"
            alt="What Next Card Background"
            width="100%"
            height="100%"
          />
          <Flex
            py="1.8rem"
            alignItems="start"
            justifyContent="space-between"
            flexDirection="column"
            pl="3rem"
          >
            <Box>
              <Text
                maxWidth="31rem"
                fontSize="1.6rem"
                lineHeight="2.3rem"
                fontWeight="700"
                color="grey.900"
                fontFamily="primaryBlack"
              >
                Lorem ipsum dolor sit amet dole Rkme Ipsum Sito!
              </Text>
            </Box>
            <a
              href="https://www.lilacinsights.com/"
              target="__blank"
              rel="noreferrer"
            >
              <Button variant="primary" width="15.3rem" mt="3rem">
                Book Now
              </Button>
            </a>
          </Flex>
        </Box> */}

          {banners?.map((bannerObject, index) => {
            return !(index % 2) ? (
              <Box
                mt="2.6rem"
                pb="2.4rem"
                pt="6.2rem"
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                background={`url("/images/home-hero-card.png")`}
                backgroundSize="cover"
                backgroundRepeat="none"
              >
                {/* <Image
                  src="/images/home-hero-card-xs-1.png"
                  alt="home-hero-card-xs-1"
                  position="absolute"
                  bottom="-1rem"
                  zIndex="0"
                />
                <Image
                  src="/images/home-hero-card-xs-2.png"
                  alt="home-hero-card-xs-2"
                  position="absolute"
                  bottom="-1rem"
                  zIndex="0"
                /> */}
                <Box px="3rem" zIndex="1">
                  <Text
                    fontSize="2.2rem"
                    lineHeight="3rem"
                    letterSpacing="0.07rem"
                    color="white"
                    fontWeight="500"
                    fontFamily="primaryRegular"
                  >
                    {bannerObject?.banner?.test_title}
                  </Text>
                  <a
                    href={bannerObject?.banner?.button?.link || ""}
                    target="__blank"
                    rel="noreferrer"
                  >
                    <Button
                      width="100%"
                      mt="2.6rem"
                      variant="primary"
                      py="1rem"
                      height="auto !important"
                    >
                      {bannerObject?.banner?.button?.text || ""}
                    </Button>
                  </a>
                  <Flex
                    alignItems="center"
                    justifyContent={
                      bannerObject?.banner?.read_more ||
                      bannerObject?.banner?.watch_vedio
                        ? bannerObject?.banner?.read_more &&
                          bannerObject?.banner?.watch_vedio
                          ? "space-between"
                          : "flex-end"
                        : ""
                    }
                    mt="1.5rem"
                  >
                    {bannerObject?.banner?.read_more && (
                      <a
                        href={bannerObject?.banner?.read_more?.link || ""}
                        target="__blank"
                        rel="noreferrer"
                      >
                        <Text
                          fontSize="1.6rem"
                          lineHeight="2.5rem"
                          color="black"
                          fontWeight="500"
                          fontFamily="primaryRegular"
                          cursor="pointer"
                        >
                          {bannerObject?.banner?.read_more?.text}
                        </Text>
                      </a>
                    )}
                    {bannerObject?.banner?.watch_vedio && (
                      <a
                        href={bannerObject?.banner?.watch_vedio?.link || ""}
                        target="__blank"
                        rel="noreferrer"
                      >
                        <Text
                          fontSize="1.6rem"
                          lineHeight="2.5rem"
                          color="black"
                          fontWeight="500"
                          fontFamily="primaryRegular"
                          onClick={() => setShowVideo(true)}
                          cursor="pointer"
                        >
                          {bannerObject?.banner?.watch_vedio?.text}
                        </Text>
                      </a>
                    )}
                  </Flex>
                </Box>
              </Box>
            ) : (
              // eslint-disable-next-line react/no-array-index-key
              <Box pt="6.5rem" px="3.5rem" key={index}>
                <Box width="100%" mx="auto">
                  <Text
                    fontSize="2.2rem"
                    lineHeight="3rem"
                    letterSpacing="0.07rem"
                    color="primary.500"
                    fontWeight="500"
                    fontFamily="primaryRegular"
                  >
                    {bannerObject?.banner?.test_title}
                  </Text>
                  <a
                    href={bannerObject?.banner?.button?.link || ""}
                    target="__blank"
                    rel="noreferrer"
                  >
                    <Button
                      width="100%"
                      mt="2.6rem"
                      variant="primary"
                      py="1rem"
                      height="auto !important"
                    >
                      {bannerObject?.banner?.button?.text || ""}
                    </Button>
                  </a>
                  <Flex
                    alignItems="center"
                    justifyContent={
                      bannerObject?.banner?.read_more ||
                      bannerObject?.banner?.watch_vedio
                        ? bannerObject?.banner?.read_more &&
                          bannerObject?.banner?.watch_vedio
                          ? "space-between"
                          : "flex-end"
                        : ""
                    }
                    mt="1.5rem"
                  >
                    {bannerObject?.banner?.read_more && (
                      <a
                        href={bannerObject?.banner?.read_more?.link || ""}
                        target="__blank"
                        rel="noreferrer"
                      >
                        <Text
                          fontSize="1.6rem"
                          lineHeight="2.5rem"
                          color="secondary.100"
                          fontWeight="500"
                          fontFamily="primaryRegular"
                          cursor="pointer"
                        >
                          {bannerObject?.banner?.read_more?.text}
                        </Text>
                      </a>
                    )}
                    {bannerObject?.banner?.watch_vedio && (
                      <a
                        href={bannerObject?.banner?.watch_vedio?.link || ""}
                        target="__blank"
                        rel="noreferrer"
                      >
                        <Text
                          fontSize="1.6rem"
                          lineHeight="2.5rem"
                          color="secondary.100"
                          fontWeight="500"
                          fontFamily="primaryRegular"
                          onClick={() => setShowVideo(true)}
                          cursor="pointer"
                        >
                          {bannerObject?.banner?.watch_vedio?.text}
                        </Text>
                      </a>
                    )}
                  </Flex>
                </Box>
              </Box>
            );
          })}
        </Box>
      )}
      {!bookNowBanner && (
        <Box mt={banners ? { xs: "4.3rem", lg: "7.5rem" } : "0"} />
      )}
      {bookNowBanner && (
        <Box
          mt={banners ? { xs: "4.3rem", lg: "7.5rem" } : "0"}
          width="100%"
          height={{ xs: "18.2rem", lg: "33.8rem" }}
          opacity="0.6"
        >
          {(isMobile()
            ? bookNowBanner?.zone?.[0]?.bg_mobile?.url
            : bookNowBanner?.zone?.[0]?.bg_desktop?.url) && (
            <Image
              position="absolute"
              // src="/images/what-next-card-bg.png"
              src={
                isMobile()
                  ? bookNowBanner?.zone?.[0]?.bg_mobile?.url
                  : bookNowBanner?.zone?.[0]?.bg_desktop?.url
              }
              alt="what-next-card-bg"
              width="100%"
              height="100%"
            />
          )}
          <Flex
            py={{ xs: "2rem", lg: "7rem" }}
            alignItems="center"
            justifyContent="space-between"
            flexDirection="column"
          >
            <Box>
              {bookNowBanner?.zone?.[0]?.heading && (
                <Text
                  fontSize={{ xs: "2.2rem", lg: "4.5rem" }}
                  lineHeight={{ xs: "3.2rem", lg: "6.6rem" }}
                  fontWeight="700"
                  color="white"
                  fontFamily="primaryBlack"
                >
                  {bookNowBanner?.zone?.[0]?.heading}
                </Text>
              )}
              {bookNowBanner?.zone?.[0]?.sub_heading && (
                <Text
                  fontSize={{ xs: "1.4rem", lg: "2.5rem" }}
                  lineHeight={{ xs: "3.2rem", lg: "6.6rem" }}
                  fontWeight="500"
                  color="white"
                  fontFamily="primaryMedium"
                  textAlign="center"
                >
                  {bookNowBanner?.zone?.[0]?.sub_heading}
                </Text>
              )}
            </Box>

            {bookNowBanner?.zone?.[0]?.book_now_btn?.link && (
              <a
                href={bookNowBanner?.zone?.[0]?.book_now_btn?.link}
                target="__blank"
                rel="noreferrer"
              >
                <Button variant="primary" width="15.3rem" mt="2.7rem">
                  {bookNowBanner?.zone?.[0]?.book_now_btn?.text}
                </Button>
              </a>
            )}
          </Flex>
        </Box>
      )}
    </Box>
  );
};
