import { createAction, createAsyncActions } from "utils/Context";
import * as constants from "./constants";

export const AUTH_ACTIONS = {
  // login: createAsyncActions(constants.LOGIN),
  requestOtp: createAsyncActions(constants.REQUEST_OTP),
  otpVerify: createAsyncActions(constants.OTP_VERIFY),
  resendOtp: createAsyncActions(constants.RESEND_OTP),
  logout: createAction(constants.LOGOUT),
  patientsListing: createAction(constants.PATIENTS_LISTING),
  patientReport: createAction(constants.PATIENT_REPORT),
  reportTests: createAction(constants.REPORT_TESTS),
  reportDisorders: createAction(constants.REPORT_DISORDERS),
  reportBanners: createAction(constants.REPORT_BANNERS),
  ratingsRecieved: createAction(constants.RATINGS_RECIEVED),
  strappiHeadings: createAction(constants.STRAPPI_HEADINGS),
};
