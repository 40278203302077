import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga4";
// import { Route } from "react-router-dom";

class GoogleAnalytics extends Component {
  componentDidMount() {
    const { location } = this.props;
    this.logPageChange(location.pathname, location.search);
  }

  componentDidUpdate({ location: prevLocation }) {
    const {
      location: { pathname, search },
    } = this.props;
    const isDifferentPathname = pathname !== prevLocation?.pathname;
    const isDifferentSearch = search !== prevLocation?.search;

    if (isDifferentPathname || isDifferentSearch) {
      this.logPageChange(pathname, search);
    }
  }

  logPageChange(pathname, search = "") {
    const page = pathname + search;
    const { location } = window;
    const { options } = this.props;
    ReactGA.set({
      page,
      location: `${location.origin}${page}`,
      ...options,
    });
    ReactGA.send({
      hitType: "pageview",
      page,
    });
    // ReactGA.event("testEvent", { testParam: true });
  }

  render() {
    return <></>;
  }
}

GoogleAnalytics.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.objectOf(PropTypes.object),
};

const RouteTracker = ({ location }) => <GoogleAnalytics location={location} />;

const init = () => {
  // const isGAEnabled = process.env.NODE_ENV === "production";
  const isGAEnabled = true;

  if (isGAEnabled) {
    ReactGA.initialize(`${process.env.REACT_APP_GOOGLE_TAG_MANAGER}`, {
      gtagOptions: { js: new Date() },
    });
    // ReactGA.initialize(`${process.env.REACT_APP_GOOGLE_TAG_MANAGER}`, {
    //   gtagOptions: { config: { debug_mode: true }, js: new Date() },
    // });G-C9VLKQMPSE
  }

  return isGAEnabled;
};

export const gtmEvent = (eventName, payload, newLocation) => {
  const params = payload;
  params.oldLocation = window.location.href;
  newLocation && (params.newLocation = newLocation);

  ReactGA.event(eventName, params);
};

export default {
  GoogleAnalytics,
  RouteTracker,
  init,
};
