// export const LOGIN = "LOGIN";
export const REQUEST_OTP = "REQUEST_OTP";
export const OTP_VERIFY = "OTP_VERIFY";
export const RESEND_OTP = "RESEND_OTP";
export const LOGOUT = "LOGOUT";
export const PATIENTS_LISTING = "PATIENTS_LISTING";
export const PATIENT_REPORT = "PATIENT_REPORT";
export const REPORT_TESTS = "REPORT_TESTS";
export const REPORT_DISORDERS = "REPORT_DISORDERS";
export const REPORT_BANNERS = "REPORT_BANNERS";
export const RATINGS_RECIEVED = "RATINGS_RECIEVED";
export const STRAPPI_HEADINGS = "STRAPPI_HEADINGS";
