export const isMobile = () =>
  typeof window !== "undefined" ? window.innerWidth <= 600 : false;
export const isTab = () =>
  typeof window !== "undefined" ? window.innerWidth <= 1024 : false;

/**
 * converts camelCase Strings to english readable Strings
 * "abcd" => "Abcd"
 * "abcdAbcd" => "Abcd abcd" | "Abcd Abcd"
 * "abcdAbcd12" => "Abcd abcd 1 2" | "Abcd Abcd 1 2"
 * value => result(isUpperCaseAfterSpace = false) | result (isUpperCaseAfterSpace = true)
 *
 * @function camelCaseToString
 * @param {string} value - camel case string to transform
 * @param {bool} isUpperCaseAfterSpace - boolean
 * @returns {string} - formatted String
 */
export const camelCaseToString = (value, isUpperCaseAfterSpace) => {
  const upperCaseBreakPoints = [...(value?.match(/[A-Z0-9]/g) || [])];
  const maxBreakPoints = upperCaseBreakPoints.length;

  let norm = "";
  let opValue = `${value}`;
  upperCaseBreakPoints?.forEach((item, index) => {
    const targetIndex = opValue.indexOf(item);
    const nextTargetIndex =
      index + 1 < maxBreakPoints
        ? opValue.indexOf(upperCaseBreakPoints[index + 1])
        : opValue.length;
    norm += `${opValue.substring(0, targetIndex)} ${opValue.substring(
      targetIndex,
      nextTargetIndex
    )}`;
    opValue = opValue.slice(nextTargetIndex);
  });

  !isUpperCaseAfterSpace && (norm = norm.toLowerCase());
  norm = norm || value;
  norm = `${norm.charAt(0).toUpperCase()}${norm.slice(1)}`;

  return norm;
};

/**
 * converts camelCase Strings to english readable Strings
 *
 * @function customArrayOfObjectsFromJson
 * @param {object} value - primary object
 * @param {string} containKey - target Key
 * @param {bool} allowAllFloat - allows all values even if there is no attribute with the corresponding `containKey`
 * @param {string} externalMap - other external objects to look for keys in value
 *            {
 *              @member {array<String>} externalPropertyName - custom Property name desired in resultant object
 *              @member {array<Object>} externalObject - external objects to search from in the corresponding order of `externalPropertyName`
 *              @member {array<String>} removableKeys - desired keys to ignore/reject
 *            }
 * @returns {object} - formatted Object
 *            {
 *              @member {string} name - `key in value`
 *              @member {any} value - `value of (key in value)`
 *              @member {object} prop - desired keys to ignore/reject
 *                {
 *                  @member {any} containKey - contains the value of containKey found in value object
 *                  @member {any} externalPropertyNameItem - contains the value of the property found in corresponding external object (x@input externalMap.externalPropertyName length)
 *                }
 *            }
 */
export const customArrayOfObjectsFromJson = ({
  value,
  containKey,
  allowAllFloat = true,
  externalMap: { externalPropertyName, externalObject, removableKeys } = {},
}) => {
  const valKeys = Object.keys(value || {});
  const valKeysFloat = valKeys?.filter(
    (valItem) => !isNaN(parseFloat(value[valItem]))
  );
  const valKeysString = valKeys?.filter((valItem) =>
    isNaN(parseFloat(value[valItem]))
  );
  const operatorArray = [];

  //---
  const externalObjectKeyList = externalObject?.map((objectItem) => {
    return Object.keys(objectItem || {});
  });

  valKeysFloat?.forEach((valItem) => {
    const operatorElement = {};
    const target = valKeysString?.find((stringItem) =>
      stringItem.includes(valItem)
    );

    // ---
    operatorElement.name = valItem;
    operatorElement.value = value[valItem];
    operatorElement.prop = {};

    const isRemovable = removableKeys?.find(
      (removableItem) => removableItem === valItem
    );
    !isRemovable &&
      externalPropertyName?.forEach((propertyNameItem, propertyNameIndex) => {
        const keyTarget = externalObjectKeyList[propertyNameIndex]?.find(
          (keyItem) => {
            return propertyNameItem?.comparisonKey
              ? keyItem
                  ?.toLowerCase()
                  ?.includes(propertyNameItem?.comparisonKey?.toLowerCase()) &&
                  keyItem?.toLowerCase()?.includes(valItem.toLowerCase())
              : keyItem?.toLowerCase() === valItem.toLowerCase();
            // keyItem?.toLowerCase()?.includes(valItem.toLowerCase())
          }
        );
        operatorElement.prop[propertyNameItem?.name] =
          externalObject[propertyNameIndex]?.[keyTarget];
      });

    // ---

    if (target && target.toLowerCase().includes(containKey)) {
      operatorElement.prop[containKey] = value?.[target];
      operatorArray.push(operatorElement);
    } else if (allowAllFloat) {
      operatorArray.push(operatorElement);
    }
  });

  return operatorArray.length ? operatorArray : value;
};

/**
 * returns color code for texts or borders as per indicators
 *
 * @function colorCode
 * @param {string} value - indicator
 * @returns {string} - color code
 */
export const colorCode = (value) => {
  return typeof value === "string"
    ? value?.toLowerCase()?.includes("high") ||
      value?.toLowerCase()?.includes("red")
      ? "accent.100"
      : value?.toLowerCase().includes("low") ||
        value?.toLowerCase().includes("green")
      ? "green.900"
      : value?.toLowerCase().includes("intermediate") ||
        value?.toLowerCase().includes("yellow")
      ? "accent.900"
      : "grey.400"
    : "grey.400";
};

/**
 * returns color code for text backgrounds as per indicators
 *
 * @function colorCodeTextBg
 * @param {string} value - indicator
 * @returns {string} - color code
 */
export const colorCodeTextBg = (value) => {
  return typeof value === "string"
    ? value?.toLowerCase()?.includes("high") ||
      value?.toLowerCase()?.includes("red")
      ? "accent.500"
      : value?.toLowerCase().includes("low") ||
        value?.toLowerCase().includes("green")
      ? "green.400"
      : value?.toLowerCase().includes("intermediate") ||
        value?.toLowerCase().includes("yellow")
      ? "accent.800"
      : "grey.100"
    : "grey.100";
};

export const colorCodeBgShadow = (value) => {
  return typeof value === "string"
    ? value?.toLowerCase()?.includes("high") ||
      value?.toLowerCase()?.includes("red")
      ? "rgba(232, 80, 91, 0.17)"
      : value?.toLowerCase().includes("low") ||
        value?.toLowerCase().includes("green")
      ? "rgba(36, 183, 0, 0.17)"
      : value?.toLowerCase().includes("intermediate") ||
        value?.toLowerCase().includes("yellow")
      ? "rgba(255, 168, 41, 0.17)"
      : "rgba(0, 0, 0, 0.1)"
    : "rgba(0, 0, 0, 0.1)";
};

export const twinDataStructureSeparation = (data, numFetus) => {
  const separatedTwinDS = [];

  const dataKeys = Object.keys(data || {});
  for (let i = 1; i <= numFetus; i++) {
    const itemKeys = dataKeys
      .filter((key) => key?.toLowerCase()?.includes(i))
      .filter((value) => !!parseFloat(data[value]));
    separatedTwinDS.push({
      title: numFetus === 1 ? "Result" : `Twin ${i} Result`,
      type: data[`resultFetus${i}`] || data?.result || "-",
      otherResult: itemKeys.map((descriptiveItem) => {
        return {
          title:
            data[`${descriptiveItem}Name`] ||
            camelCaseToString(descriptiveItem, true),
          res: data[descriptiveItem],
        };
      }),
    });
  }

  const itemKeys = dataKeys
    .filter((key) => key?.match(/^[a-zA-Z]+$/))
    .filter((value) => !!parseFloat(data[value]));
  itemKeys.forEach((descriptiveItem) => {
    separatedTwinDS[0]?.otherResult?.push({
      title:
        data[`${descriptiveItem}Name`] ||
        camelCaseToString(descriptiveItem, true),
      res: data[descriptiveItem],
    });
  });
  return separatedTwinDS;
};

export const strappiQueryFilterCondition = (condition, value1, value2) => {
  if (condition === "eq") {
    if (value1 === value2) {
      return true;
    }
  }
  if (condition === "lt") {
    if (value1 < value2) {
      return true;
    }
  }

  if (condition === "lte") {
    if (value1 <= value2) {
      return true;
    }
  }
  if (condition === "gt") {
    if (value1 > value2) {
      return true;
    }
  }
  if (condition === "gte") {
    if (value1 >= value2) {
      return true;
    }
  }

  return undefined;
};
