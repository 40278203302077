import React, { useCallback, useEffect, useState } from "react";

import useAuth from "contexts/Auth";
import { Box, Heading, Grid, Image, Flex, Text } from "atoms";
import { ResultCard, MOMCard, InfoModal } from "molecules";
import {
  customArrayOfObjectsFromJson,
  twinDataStructureSeparation,
} from "utils/utilities";
import Markdown from "markdown-to-jsx";

export const Results = ({
  sampleId,
  risks,
  MOM,
  interpretation,
  logoUrl,
  testResult,
  headings,
}) => {
  const [selectedCard, setSelectedCard] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const riskKeys = Object.keys(risks || {});
  const modifiedMOM = customArrayOfObjectsFromJson({
    value: MOM,
    containKey: "color",
    externalMap: {
      externalPropertyName: [
        { name: "name", comparisonKey: "name" },
        { name: "color", comparisonKey: "color" },
      ],
      externalObject: [MOM, MOM],
    },
  });

  const resultOverviewHeading = headings?.heading_component?.find(
    (item) => item.key === "resultOverview"
  );
  const momHeading = headings?.heading_component?.find(
    (item) => item.key === "mom"
  );
  const interpretationHeading = headings?.heading_component?.find(
    (item) => item.key === "interpretation"
  );

  // operating function for reading Interpretation sent by backend and custom object initialisation
  // const customFormattingInterpretation = useCallback((value) => {
  //   const fragments = value.split(/\bscreen\b/gi);

  //   if (fragments.length > 1) {
  //     const part1 = fragments[0] || "";
  //     let part2 = "";
  //     let part3 = "";
  //     const nextWordAntiMatch = fragments[1]?.match(/\W/g) || [];
  //     if (nextWordAntiMatch.length) {
  //       const subFragments = fragments[1]?.replace(nextWordAntiMatch[0], "");
  //       // subFragments = subFragments?.split(nextWordAntiMatch[1] || "~");
  //       const targetSplit = subFragments.indexOf(nextWordAntiMatch[1] || "~");
  //       if (targetSplit >= 0) {
  //         part2 = `SCREEN ${subFragments.slice(0, targetSplit)}`;
  //         part3 = subFragments.slice(targetSplit);
  //       }
  //       // if (subFragments.length > 1) {
  //       // part2 = `SCREEN ${subFragments[0]}` || "";
  //       // part3 = subFragments[1] || "";
  //       // }

  //       return {
  //         part1,
  //         part2,
  //         part3,
  //       };
  //     }
  //   }
  //   // const reg = \W*((?i)rocket(?-i))\W*;
  //   const fragmentsIntermediate = value.split(/\bintermediate\b/gi);
  //   if (fragmentsIntermediate.length > 1) {
  //     const part1 = fragmentsIntermediate[0] || "";
  //     let part2 = "";
  //     let part3 = "";
  //     const nextWordAntiMatch = fragmentsIntermediate[1]?.match(/\W/g) || [];
  //     if (nextWordAntiMatch.length) {
  //       const subFragments = fragmentsIntermediate[1]?.replace(
  //         nextWordAntiMatch[0],
  //         ""
  //       );
  //       const targetSplit = subFragments.indexOf(nextWordAntiMatch[1] || "~");
  //       if (targetSplit >= 0) {
  //         part2 = `INTERMEDIATE ${subFragments.slice(0, targetSplit)}`;
  //         part3 = subFragments.slice(targetSplit);
  //       }

  //       return {
  //         part1,
  //         part2,
  //         part3,
  //       };
  //     }
  //   }
  //   return {
  //     part1: value,
  //   };
  // }, []);

  // testing
  // const interpretationTags = document.getElementsByClassName("MsoNormal");
  // const interpretationTagText = customFormattingInterpretation(
  //   interpretationTags[1]?.outerText || ""
  // );

  const {
    state: { testDisorders },
    // actions: { getStrappiDisorders },
  } = useAuth();
  const [fetusCount, setFetusCount] = useState(0);

  const getDisorderName = useCallback(
    (key) => {
      const selectedDisorder = testDisorders?.find(
        (disorder) => disorder?.key === key
      );

      return selectedDisorder?.name;
    },
    [testDisorders]
  );

  const getDisorderTooltip = useCallback(
    (key) => {
      const selectedDisorder = testDisorders?.find(
        (disorder) => disorder?.key === key
      );

      return selectedDisorder?.description;
    },
    [testDisorders]
  );

  useEffect(() => {
    const numFetus = Object.keys(testResult || {});
    setFetusCount(numFetus.length || 0);
  }, [testResult]);

  return (
    <Box maxWidth="100%" overflow="hidden">
      {showModal && (
        <InfoModal
          isOpen={!!showModal}
          onRequestClose={() => setShowModal(false)}
          ariaHideApp={false}
          title={getDisorderName(showModal)}
          info={getDisorderTooltip(showModal)}
          height={{ xs: "35rem" }}
          keyTooltip={showModal}
        />
      )}

      <Grid
        mt={{ xs: "2rem", lg: "0rem" }}
        gridAutoColumns={{ lg: "minmax(12rem, auto)" }}
        gridTemplateColumns={{ lg: "unset" }}
        gridAutoFlow={{ lg: "column" }}
        gridColumnGap={{ xs: "0", lg: "1.5rem" }}
        gridRowGap={{ xs: "1rem", lg: "0" }}
      >
        <Box
          width={{ xs: "11.8rem", lg: "31rem" }}
          height={{ xs: "3.6rem", lg: "9.6rem" }}
        >
          <Image src={logoUrl || ""} alt="report-img" />
        </Box>
        <Box />
        <Box />
        <Box />
        {[0].map((item, index) => (
          <Flex
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            maxWidth={{ lg: "30rem" }}
            px={{ lg: "1.5rem" }}
            py={{ lg: "2.2rem" }}
            pr={{ xs: "10rem" }}
            alignItems={{ lg: "center" }}
            justifyContent={{ xs: "space-between" }}
            borderRadius={{ lg: "1rem" }}
            border={{ lg: "0.3rem solid" }}
            borderColor={{ lg: "primary.500" }}
            ml={{ xs: "1.6rem", lg: "0" }}
          >
            <Text
              fontSize={{ xs: "1.4rem", lg: "2rem" }}
              fontWeight="600"
              lineHeight={{ xs: "2.1rem", lg: "3rem" }}
              fontFamily="primaryRegular"
              color="primary.200"
            >
              Sample ID
            </Text>
            <Text
              fontSize={{ xs: "1.4rem", lg: "2rem" }}
              fontWeight="400"
              lineHeight={{ xs: "2.1rem", lg: "2.4rem" }}
              fontFamily="primaryRegular"
              color="grey.900"
            >
              {/* {sampleId?.slice(0, 6) || sampleId}XXXXX */}
              {sampleId || ""}
            </Text>
          </Flex>
        ))}
      </Grid>

      {Array.isArray(interpretation) && interpretation.length ? (
        <Box mt="6rem">
          {interpretationHeading && (
            <Flex alignItems="center">
              <Heading
                name={interpretationHeading?.title}
                fontSize={{ xs: "1.8rem", lg: "2.5rem" }}
                lineHeight={{ xs: "2.9rem", lg: "4rem" }}
              />
              {interpretationHeading?.tooltipKey && (
                <Box
                  display="flex"
                  width="1.6rem"
                  height="1.6rem"
                  borderRadius="50%"
                  border="0.2rem solid"
                  borderColor="accent.900"
                  alignItems="center"
                  justifyContent="center"
                  cursor="pointer"
                  onClick={() =>
                    setShowModal(interpretationHeading?.tooltipKey)
                  }
                  ml="0.3rem"
                >
                  <Text
                    fontSize="1.2rem"
                    lineHeight="1.5rem"
                    fontWeight="700"
                    color="accent.900"
                    mt="0.3rem"
                  >
                    ?
                  </Text>
                </Box>
              )}
            </Flex>
          )}

          <Flex
            flexDirection="column"
            bg="white"
            mb={{ xs: "1.2rem", lg: "3rem" }}
          >
            {/* <Box bg="white" width="100%"> */}
            {Array.isArray(interpretation) && interpretation.length
              ? interpretation?.map((item, index) => (
                  <Flex
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    boxShadow={{
                      xs: "0px 1.79459px 8.97297px rgba(0, 0, 0, 0.1)",
                      lg: "0px 1.79459px 8.97297px rgba(0, 0, 0, 0.1)",
                    }}
                    borderRadius="0.8rem"
                    // border={{ lg: "0.2rem solid" }}
                    // borderColor={{ lg: "grey.200" }}
                    mt={{ xs: "1.2rem", lg: "3rem" }}
                    maxWidth="98%"
                  >
                    <Box
                      display={{ xs: "block", lg: "block" }}
                      minWidth={{ xs: "1rem", lg: "1.6rem" }}
                      bg={{
                        xs:
                          item?.interpretation_risk === "high"
                            ? "accent.100"
                            : item?.interpretation_risk === "low"
                            ? "green.900"
                            : "accent.900",
                        lg:
                          item?.interpretation_risk === "high"
                            ? "accent.100"
                            : item?.interpretation_risk === "low"
                            ? "green.900"
                            : "accent.900",
                      }}
                      height="auto"
                      borderTopLeftRadius="0.8rem"
                      borderBottomLeftRadius="0.8rem"
                    />
                    <Box
                      py={{ lg: "1.4rem" }}
                      px={{ xs: ".8rem", lg: "1.9rem" }}
                      fontSize="1.6rem"
                    >
                      <Markdown>{item?.interpretation || ""}</Markdown>
                    </Box>
                  </Flex>
                ))
              : ""}
            {/* </Box> */}
          </Flex>
        </Box>
      ) : (
        ""
      )}

      {resultOverviewHeading && (
        <Flex alignItems="center" mt={{ xs: "4.1rem", lg: "6.8rem" }}>
          <Heading
            name={resultOverviewHeading?.title}
            fontSize={{ xs: "1.8rem", lg: "2.5rem" }}
            lineHeight={{ xs: "2.9rem", lg: "4rem" }}
          />
          {resultOverviewHeading?.tooltipKey && (
            <Box
              display="flex"
              width="1.6rem"
              height="1.6rem"
              borderRadius="50%"
              border="0.2rem solid"
              borderColor="accent.900"
              alignItems="center"
              justifyContent="center"
              cursor="pointer"
              onClick={() => setShowModal(resultOverviewHeading?.tooltipKey)}
              ml="0.3rem"
            >
              <Text
                fontSize="1.2rem"
                lineHeight="1.5rem"
                fontWeight="700"
                color="accent.900"
                mt="0.3rem"
              >
                ?
              </Text>
            </Box>
          )}
        </Flex>
      )}
      <Grid
        mt={{ xs: "2rem", lg: "3rem" }}
        gridTemplateColumns={{ lg: "repeat(3, 1fr)" }}
        gridColumnGap={{ xs: "0", lg: "1.3rem" }}
        gridRowGap={{ xs: "3.1rem", lg: "3.5rem" }}
      >
        {riskKeys?.map((item) => {
          const descriptiveKeys = [];
          let riskType = "low";
          Object.keys(risks[item] || {}).forEach((value) => {
            !!parseFloat(risks[item][value]) && descriptiveKeys.push(value);
          });
          for (let i = 1; i <= fetusCount; i++) {
            const result =
              risks[item]?.[`resultFetus${i}`] || risks[item]?.result || "";
            if (
              result?.toLowerCase()?.includes("high") ||
              result?.toLowerCase()?.includes("positive") ||
              result?.toLowerCase()?.includes("abnormal")
            ) {
              riskType = "high";
              break;
            } else if (result?.toLowerCase()?.includes("intermediate")) {
              riskType = "intermediate";
            }
          }
          return (
            <ResultCard
              key={item}
              id={item}
              title="Disorder"
              // name={camelCaseToString(item)}
              name={getDisorderName(item)}
              type={riskType}
              result={twinDataStructureSeparation(risks[item], fetusCount)}
              selectedCard={selectedCard}
              setSelectedCard={setSelectedCard}
              info={getDisorderTooltip(item)}
              keyTooltip={item}
            />
          );
        })}
      </Grid>

      {modifiedMOM && Array.isArray(modifiedMOM) ? (
        <Box mt={{ xs: "4.1rem", lg: "6.8rem" }}>
          {momHeading && (
            <Flex alignItems="center">
              <Heading
                name={momHeading?.title}
                fontSize={{ xs: "1.8rem", lg: "2.5rem" }}
                lineHeight={{ xs: "2.9rem", lg: "4rem" }}
              />
              {momHeading?.tooltipKey && (
                <Box
                  display="flex"
                  width="1.6rem"
                  height="1.6rem"
                  borderRadius="50%"
                  border="0.2rem solid"
                  borderColor="accent.900"
                  alignItems="center"
                  justifyContent="center"
                  cursor="pointer"
                  onClick={() => setShowModal(momHeading?.tooltipKey)}
                  ml="0.3rem"
                >
                  <Text
                    fontSize="1.2rem"
                    lineHeight="1.5rem"
                    fontWeight="700"
                    color="accent.900"
                    mt="0.3rem"
                  >
                    ?
                  </Text>
                </Box>
              )}
            </Flex>
          )}

          <Grid
            mt={{ xs: "3rem" }}
            gridTemplateColumns={{ lg: "repeat(4, 1fr)" }}
            gridColumnGap={{ xs: "0", lg: "2.7rem" }}
            gridRowGap={{ xs: "2.2rem" }}
          >
            {modifiedMOM &&
              Array.isArray(modifiedMOM) &&
              modifiedMOM?.map((momItem, index) => (
                <Box
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  // display={index < 2 ? "block" : { xs: "none", lg: "block" }}
                >
                  <MOMCard
                    title={momItem.prop.name}
                    value={momItem.value}
                    type={momItem.prop.color}
                    keyTooltip={momItem.name}
                  />
                </Box>
              ))}
          </Grid>
        </Box>
      ) : (
        ""
      )}
      {/* {interpretationTagText?.part1 && (
        <Box mt="6rem">
          <Flex alignItems="center">
            <Heading
              name="Interpretation"
              fontSize={{ xs: "1.8rem", lg: "2.5rem" }}
              lineHeight={{ xs: "2.9rem", lg: "4rem" }}
            />
            <Box
              display="flex"
              width="1.6rem"
              height="1.6rem"
              borderRadius="50%"
              border="0.2rem solid"
              borderColor="accent.900"
              alignItems="center"
              justifyContent="center"
              cursor="pointer"
              onClick={() => setShowModal("INTERPRETATION")}
              ml="0.3rem"
            >
              <Text
                fontSize="1.2rem"
                lineHeight="1.5rem"
                fontWeight="700"
                color="accent.900"
                mt="0.3rem"
              >
                ?
              </Text>
            </Box>
          </Flex>

          <Flex
            boxShadow={{ lg: "0px 1.79459px 8.97297px rgba(36, 183, 0, 0.17)" }}
            borderRadius="0.8rem"
            border={{ lg: "0.2rem solid" }}
            borderColor={{ lg: "grey.200" }}
            mt={{ xs: "1.2rem", lg: "3rem" }}
          >
            <Box
              display={{ xs: "none", lg: "block" }}
              width={{ lg: "1.6rem" }}
              bg={{
                lg: interpretationTagText?.part2
                  ?.toLowerCase()
                  ?.includes("positive")
                  ? "accent.100"
                  : "green.900",
              }}
              height="auto"
              borderTopLeftRadius="0.8rem"
              borderBottomLeftRadius="0.8rem"
            />
            <Box bg="white" width="100%">
              <Text
                py={{ lg: "1.4rem" }}
                pl={{ lg: "1.9rem" }}
                fontSize="1.6rem"
                lineHeight="3rem"
                fontFamily={{ xs: "primaryLight", lg: "primaryMedium" }}
                fontWeight={{ xs: "300", lg: "500" }}
                color="grey.900"
              >
                {interpretationTagText.part1}{" "}
                <Text
                  as="span"
                  color={
                    interpretationTagText?.part2
                      ?.toLowerCase()
                      ?.includes("positive")
                      ? "accent.100"
                      : interpretationTagText?.part2
                          ?.toLowerCase()
                          ?.includes("negative")
                      ? "green.900"
                      : "accent.900"
                  }
                >
                  {interpretationTagText?.part2}
                </Text>{" "}
                {interpretationTagText?.part3}
              </Text>
            </Box>
          </Flex>
        </Box>
      )} */}
      {/* <div
        style={{ display: "none" }}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: interpretation }}
      /> */}
    </Box>
  );
};
