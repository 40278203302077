import React from "react";

import { HomePage } from "templates";

export const Home = () => {
  return (
    <>
      <HomePage />
    </>
  );
};

export default Home;
