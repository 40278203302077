import { loadCookie, saveCookie } from "./cookie";

/**
 * Store object in cookies, sessionStorage, or localStorage
 * Pure function, returns original object.
 *
 * @function persist
 * @param {string} name - key to save under
 * @param {object} obj - object to store
 * @returns {object} - original object
 */
export const persist = (name, obj, opt) => {
  // later add-ons for local-storage and session-storage
  saveCookie(name, obj, opt);
  return obj;
};

/**
 * Returns object stored in cookies, sessionStorage, or localStorage
 * by name and accepts default values.
 *
 * Pure function, returns object with values and/or defaults.
 *
 * @function hydrate
 * @param {string} name - key to load from
 * @param {object} keysWithDefaults - object with default values associated with keys
 * @returns {object | null} - deserialized values with defaults if specified keys did not exist
 */
export const hydrate = (name, keysWithDefaults) => {
  // later add-ons for local-storage and session-storage
  const content = loadCookie(name);

  if (!content) {
    return keysWithDefaults || null;
  }

  if (keysWithDefaults) {
    return Object.entries(keysWithDefaults).reduce((results, [key, value]) => {
      // If a key does not have a value, set the default value for that key
      if (!results[key]) {
        // eslint-disable-next-line no-param-reassign
        results[key] = value;
      }

      return results;
    }, content);
  }

  return content;
};
