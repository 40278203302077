import React from "react";
import Modal from "react-modal";
import ReactPlayer from "react-player";

import { Box, Flex, Image } from "atoms";
import MemoClose from "assets/icons/Close";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "4px",
    padding: "0px",
    border: "none",
    background: "transparent",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 1, 0.6)",
    zIndex: 2,
  },
};

export const VideoModal = ({
  isOpen,
  onRequestClose,
  ariaHideApp,
  videoUrl,
  thumbnail,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      ariaHideApp={ariaHideApp}
    >
      <Box width="100%">
        <Flex
          alignItems="center"
          justifyContent="end"
          px="2.7rem"
          pt={{ xs: "2.6rem", lg: "3.8rem" }}
        >
          <Box
            onClick={onRequestClose}
            cursor="pointer"
            zIndex={2}
            width={{ xs: "1.2rem", lg: "2.7rem" }}
            height={{ xs: "1.2rem", lg: "2.7rem" }}
            top={{ xs: "-0.3rem", lg: "0rem" }}
            color="white"
          >
            <MemoClose width="100%" height="100%" />
          </Box>
        </Flex>
        <Box
          py="2.5rem"
          px="2rem"
          width={{ xs: "95vw", lg: "79rem" }}
          height={{ xs: "62vw", lg: "52.5rem" }}
          mx="auto"
        >
          <ReactPlayer
            url={videoUrl}
            controls={false}
            width="100%"
            height="100%"
            light={thumbnail}
            playIcon={<Image src="/images/play_button.png" alt="playButton" />}
          />
        </Box>
      </Box>
    </Modal>
  );
};
