import React, { useContext } from "react";

import { Header, Footer } from "organisms";
import { Box } from "atoms";
import { ReportContext } from "utils/Contexts";

export const Layout = ({ children }) => {
  const { showNav } = useContext(ReportContext);

  return (
    <>
      <Header />
      <Box
        pt={
          showNav
            ? { xs: "17.7rem", lg: "17.7rem" }
            : { xs: "9.6rem", lg: "17.7rem" }
        }
      >
        {children}
      </Box>
      <Footer />
    </>
  );
};
