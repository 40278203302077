import * as React from "react";

function Arrow(props) {
  return (
    <svg
      width="11"
      height="18"
      viewBox="0 0 11 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 17L2 9L10 1"
        stroke="url(#paint0_linear_1005_932)"
        strokeWidth="2"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1005_932"
          x1="10"
          y1="27.8936"
          x2="-1.74364"
          y2="26.6684"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#652D90" />
          <stop offset="1" stopColor="#AE7FB8" />
        </linearGradient>
      </defs>
    </svg>
  );
}

const MemoArrow = React.memo(Arrow);
export default MemoArrow;
