import React, { useCallback, useContext, useEffect, useState } from "react";

import useAuth from "contexts/Auth";
import { Box } from "atoms";
import { ReportContext } from "utils/Contexts";
import {
  HomeHero,
  TestResultCard,
  HomeSlider,
  // XsCards,
  HomeCards,
} from "./molecules";

export const HomePage = () => {
  const { setShowNav } = useContext(ReportContext);

  const [strapiHomePage, setStrapiHomePage] = useState({});
  const [strapiHomePageBanners, setStrapiHomePageBanners] = useState([]);

  const {
    state: { userData, report, patients },
    actions: { getPatientReportsListing, getPatientReportsData },
  } = useAuth();

  const getSection = useCallback(
    (value) => {
      return (
        strapiHomePage?.zone?.filter(
          (section) => section.__component === value
        )[0] || {}
      );
    },
    [strapiHomePage]
  );
  const fetchHomePage = useCallback(async () => {
    try {
      const test = await fetch(
        // eslint-disable-next-line prefer-template
        `${process.env.REACT_APP_STRAPI}/pages?slug=home`
      );
      const testdata = await test.json();

      setStrapiHomePage(testdata[0] || {});
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    fetchHomePage();
  }, []);

  const fetchBanners = useCallback(
    /**
     * @param {string} slug
     */
    // eslint-disable-next-line consistent-return
    async (slug) => {
      try {
        const test = await fetch(
          // eslint-disable-next-line prefer-template
          `${process.env.REACT_APP_STRAPI}/test-banners?` +
            new URLSearchParams({
              slug,
            })
        );
        const testdata = await test.json();
        return testdata[0];
      } catch (e) {
        console.error(e);
      }
    },
    []
  );
  const fetchHomePageBanners = useCallback(
    /**
     * @param {string} test_name
     * @param {string} test_window
     * @param {string} test_result
     */
    async (test_name, test_window, test_result) => {
      try {
        const test = await fetch(
          // eslint-disable-next-line prefer-template
          `${process.env.REACT_APP_STRAPI}/home-rule-engines?` +
            new URLSearchParams({
              test_name,
              test_window,
              test_result,
            })
        );

        const testdata = await test.json();

        // banner_association_key
        Promise.all(
          testdata[0]?.banner_association_key?.map(async (item) => {
            const bannerItem = await fetchBanners(item?.key);
            return bannerItem;
          })
        ).then((value) => {
          setStrapiHomePageBanners(value);
        });

        // old code
        // setStrapiHomePageBanners(testdata[0] || {});
      } catch (e) {
        console.error(e);
      }
    },
    []
  );

  useEffect(() => {
    if (report) {
      fetchHomePageBanners(
        report?.additionalInfo?.testName,
        report?.additionalInfo?.testICDCode,
        report?.overallRiskValue
      );
      // fetchHomePageBanners(
      //   report?.additionalInfo?.testName
      //     ?.split("-")
      //     ?.reduce((previousString, currentString, currentIndex) => {
      //       return currentIndex === 1
      //         ? previousString
      //         : `${previousString}${currentString}`;
      //     }, "")
      //     ?.toLowerCase(),
      //   `${report?.additionalInfo?.testICDCode?.split(" ")[0] || ""} ${
      //     report?.additionalInfo?.testICDCode?.split(" ")[1] || ""
      //   }`?.toLowerCase(),
      //   report?.overallRiskValue?.toLowerCase()
      // );
    } else {
      patients
        ? (async () => {
            if (patients) {
              const patient = patients?.findIndex(
                (val) => val.patientId === userData.patient.limp_patient_id
              );
              getPatientReportsData(patients[patient] || {});
            }
          })()
        : getPatientReportsListing(userData?.patient?.mobile);
    }
  }, [report, fetchHomePageBanners, patients]);

  useEffect(() => {
    setShowNav(false);
  }, []);

  return (
    <Box
      maxWidth="144rem"
      overflow="hidden"
      mx="auto"
      pt={{ lg: "7.8rem" }}
      pb={{ lg: "9rem" }}
      px={{ lg: "7rem" }}
    >
      {/* <Box display={{ xs: "block", lg: "none" }} width="100%">
        <Image
          src={getSection("sections.home-hero")?.hero_img_mobile?.url}
          alt="home-hero"
        />
      </Box> */}
      <TestResultCard
        testName={report?.additionalInfo?.testName}
        bgImage={getSection("sections.home-hero")?.hero_img_mobile?.url}
      />
      <HomeHero
        heading={getSection("sections.home-hero")?.heading}
        description={getSection("sections.home-hero")?.description}
        video={getSection("sections.home-hero")?.video}
      />
      {/* <Flex
        display={{ xs: "flex !important", lg: "none !important" }}
        justifyContent="center"
      >
        <XsCards cards={getSection("sections.home-slider")?.home_cards} />
      </Flex> */}
      <Box display={{ xs: "block", lg: "block" }}>
        <HomeSlider cards={getSection("sections.home-slider")?.home_cards} />
      </Box>
      <HomeCards cards={strapiHomePageBanners} />
      {/* <HomeCards cards={strapiHomePageBanners?.test_banners} /> */}
    </Box>
  );
};
