import React from "react";
import { Link } from "react-router-dom";

import { Box, Text, Button, Image } from "atoms";

export const VerifiedUser = ({ redirectURL }) => {
  return (
    <>
      <Box
        maxWidth={{ xs: "36rem", lg: "52rem" }}
        mx="auto"
        bg="white"
        boxShadow="0px 4px 21px 1px rgba(1, 1, 1, 0.1)"
        borderRadius="1rem"
        mt={{ xs: "5.9rem", lg: "0" }}
        px={{ xs: "6rem", lg: "7rem" }}
        pt={{ xs: "2.4rem", lg: "3rem" }}
        pb={{ xs: "2.3rem", lg: "5rem" }}
      >
        <Box
          width={{ xs: "9.9rem", lg: "12.2rem" }}
          height={{ xs: "9.9rem", lg: "12.2rem" }}
          mx="auto"
        >
          <Image src="/images/checked.png" alt="checked" />
        </Box>
        <Text
          fontSize={{ xs: "2rem", lg: "3.2rem" }}
          lineHeight={{ xs: "3.3rem", lg: "5.2rem" }}
          fontWeight="600"
          fontFamily="primaryRegular"
          color="grey.900"
          textAlign="center"
          maxWidth="100%"
          mx="auto"
          letterSpacing="0.07em"
          mt={{ xs: "2.3rem", lg: "3.8rem" }}
        >
          Verified!
        </Text>

        <Text
          fontSize={{ xs: "1.4rem", lg: "2.5rem" }}
          lineHeight={{ xs: "1.8rem", lg: "3.9rem" }}
          fontWeight="300"
          fontFamily="primaryRegular"
          color="grey.900"
          textAlign="center"
          maxWidth={{ xs: "30.9rem", lg: "45.2rem" }}
          letterSpacing="0.07em"
          mt="1.5rem"
        >
          Your account has been verified successfully!
        </Text>
        <Box display={{ xs: "none", lg: "block" }} width="37.9rem" mx="auto">
          <Link to={redirectURL}>
            <Button
              variant="primary"
              textTransform="uppercase"
              mt="6.4rem"
              onClick={undefined}
            >
              Go to report
            </Button>
          </Link>
        </Box>
      </Box>
      <Box
        display={{ xs: "block", lg: "none" }}
        width="100%"
        mx="auto"
        px="3rem"
        pb={{ xs: "4rem", lg: "0" }}
      >
        <Link to={redirectURL}>
          <Button
            variant="primary"
            width="100%"
            textTransform="uppercase"
            mt="4.6rem"
          >
            Go to report
          </Button>
        </Link>
      </Box>
    </>
  );
};
