import React, { useEffect, useState } from "react";
import { gtmEvent } from "utils/GoogleAnalytics";
import GTM_EVENTS from "utils/gtm-events.constant";

export const ReportContext = React.createContext();

export const ReportProvider = ({ children }) => {
  const navData = [
    {
      id: "0",
      title: "About Test",
      img: "/",
    },
    {
      id: "1",
      title: "Results",
      img: "/",
    },
    {
      id: "2",
      title: "What Next",
      img: "/",
    },
    {
      id: "3",
      title: "Specimen Details",
      img: "/",
    },
  ];

  const [activeReportId, setActiveReportId] = useState(navData[0].id);
  const [showNav, setShowNav] = useState(false);

  useEffect(() => {
    const eventData = navData?.find((item) => item?.id === activeReportId);
    gtmEvent(GTM_EVENTS?.TAB_CHANGE_EVENT, eventData || {});
  }, [activeReportId]);

  return (
    <ReportContext.Provider
      value={{
        navData,
        activeReportId,
        setActiveReportId,
        showNav,
        setShowNav,
      }}
    >
      {children}
    </ReportContext.Provider>
  );
};
