import React, { useCallback, useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Box, Flex, Text, Button } from "atoms";
import { EmojiCard } from "molecules";
import { ReportContext } from "utils/Contexts";
import useAuth from "contexts/Auth";
import MemoLogoRound from "assets/icons/LogoRound";
import MemoMenuMobileIcon from "assets/icons/MenuMobileIcon";

export const Header = () => {
  const { navData, activeReportId, setActiveReportId, showNav } =
    useContext(ReportContext);
  const [emojiPopUp, setEmojiPopUp] = useState(true);

  const {
    state: { userData, isRated },
    actions: { ratingsStrappi },
  } = useAuth();
  const navigate = useNavigate();

  const onRating = useCallback(
    (emojiType, emojiScore) => {
      if (!isRated) {
        ratingsStrappi(emojiType, emojiScore, new Date(), userData?.patient);
      }
    },
    [userData, ratingsStrappi, isRated]
  );

  return (
    <Box
      position="fixed"
      width="100%"
      maxWidth={{ lg: "144rem" }}
      zIndex={5}
      transition="all 0.3s ease 0s"
    >
      <Box
        backgroundImage="linear-gradient(101.79deg, #662C72 -11.75%, #AE7FB8 111.02%)"
        boxShadow="inset 0px 4px 8px rgba(0, 0, 0, 0.1)"
        color="white"
      >
        <Flex
          px={{ xs: "3rem", lg: "7rem" }}
          pt={{ xs: "2.7rem", lg: "3rem" }}
          pb={{ xs: "2.7rem", lg: "3rem" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Link to="/">
            <Flex alignItems="center">
              <Box
                width={{ xs: "4.2rem", lg: "8.9rem" }}
                height={{ xs: "4.2rem", lg: "8.9rem" }}
                mr={{ xs: "1.1rem", lg: "2.4rem" }}
                color="primary.200"
              >
                <MemoLogoRound width="100%" height="100%" />
              </Box>
              <Text
                fontSize={{ xs: "1.8rem", lg: "3.5rem" }}
                fontWeight="500"
                fontFamily="primaryRegular"
                lineHeight={{ xs: "2.5rem", lg: "5.6rem" }}
              >
                Hello {userData?.patient?.name.split(" ")[0] ?? ""} !
              </Text>
            </Flex>
          </Link>

          <Box
            display={showNav ? { xs: "none", lg: "flex" } : "none"}
            alignItems="center"
            justifyContent="space-between"
          >
            {navData.map((item, index) => (
              <Text
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                fontSize="1.8rem"
                fontWeight={activeReportId === item.id ? "700" : "400"}
                fontFamily={
                  activeReportId === item.id ? "primaryBlack" : "primaryRegular"
                }
                lineHeight="2.5rem"
                textTransform="capitalize"
                mx="2.2rem"
                borderBottom={
                  activeReportId === item.id ? "0.4rem solid" : "none"
                }
                borderColor="white"
                onClick={() => setActiveReportId(item.id)}
                cursor="pointer"
              >
                {item.title}
              </Text>
            ))}
          </Box>

          <Button
            width={{ xs: "8.1rem", lg: "14.1rem" }}
            variant="default"
            onClick={() => navigate("/logout")}
          >
            Logout
          </Button>
        </Flex>
        {!isRated && emojiPopUp && (
          <Box
            position="absolute"
            top="12rem"
            left="18.3rem"
            display={{ xs: "none", lg: "block" }}
          >
            <EmojiCard
              onClose={() => setEmojiPopUp(false)}
              isVisible={!isRated && emojiPopUp}
              onRating={onRating}
            />
          </Box>
        )}
      </Box>
      <Box
        display={showNav ? { xs: "grid", lg: "none" } : "none"}
        height="8.2rem"
        alignItems="start"
        bg="primary.500"
        pt="1.9rem"
        px="0.5rem"
        borderBottomLeftRadius="1.5rem"
        borderBottomRightRadius="1.5rem"
        gridColumnGap="0.7rem"
        gridAutoColumns="minmax(5rem, auto)"
        gridAutoFlow="column"
      >
        {navData.map((item, index) => (
          <Flex
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            flexDirection="column"
            alignItems="center"
            onClick={() => setActiveReportId(item.id)}
          >
            <Text
              fontSize="1.4rem"
              lineHeight="2.2rem"
              fontFamily="primaryRegular"
              fontWeight={activeReportId === item.id ? "600" : "300"}
              color="white"
              whitespace="nowrap"
              cursor="pointer"
            >
              {item.title}
            </Text>
            <Box
              position={activeReportId === item.id ? "absolute" : "relative"}
              mt={activeReportId === item.id ? "0" : "1.1rem"}
              border={activeReportId === item.id ? "0.6rem solid" : "none"}
              borderColor="white"
              borderRadius="50%"
              top={activeReportId === item.id ? "3rem" : "0"}
              zIndex={activeReportId === item.id ? "1" : "0"}
            >
              <Flex
                width={activeReportId === item.id ? "4rem" : "1.8rem"}
                height={activeReportId === item.id ? "4rem" : "2.1rem"}
                alignItems="center"
                justifyContent="center"
                bg="primary.500"
                borderRadius="50%"
                color="white"
              >
                <MemoMenuMobileIcon />
              </Flex>
            </Box>
          </Flex>
        ))}
      </Box>
    </Box>
  );
};
