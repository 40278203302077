import React, { useCallback, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import Markdown from "markdown-to-jsx";

import { Box, Text, Grid, Image, Heading, Flex } from "atoms";
import { isMobile } from "utils/utilities";
import ReactTooltip from "react-tooltip";
import { InfoModal } from "molecules";
import useAuth from "contexts/Auth";

// const videoList = [
//   {
//     id: "1",
//     title: "What is Down syndrome?",
//     thumbnail: "/images/thumbnail.png",
//     videoSrc: "https://www.youtube.com/watch?v=ysz5S6PUM-U",
//   },
//   {
//     id: "2",
//     title: "What is Down syndrome?",
//     thumbnail: "/images/syndrome_img.png",
//     videoSrc: "https://www.youtube.com/watch?v=ysz5S6PUM-U",
//   },
//   {
//     id: "3",
//     title: "What is Down syndrome?",
//     thumbnail: "/images/thumbnail.png",
//     videoSrc: "https://www.youtube.com/watch?v=ysz5S6PUM-U",
//   },
//   {
//     id: "4",
//     title: "What is Down syndrome?",
//     thumbnail: "/images/syndrome_img.png",
//     videoSrc: "https://www.youtube.com/watch?v=ysz5S6PUM-U",
//   },
//   {
//     id: "5",
//     title: "What is Down syndrome?",
//     thumbnail: "/images/thumbnail.png",
//     videoSrc: "https://www.youtube.com/watch?v=ysz5S6PUM-U",
//   },
//   {
//     id: "6",
//     title: "What is Down syndrome?",
//     thumbnail: "/images/syndrome_img.png",
//     videoSrc: "https://www.youtube.com/watch?v=ysz5S6PUM-U",
//   },
//   {
//     id: "7",
//     title: "What is Down syndrome?",
//     thumbnail: "/images/thumbnail.png",
//     videoSrc: "https://www.youtube.com/watch?v=ysz5S6PUM-U",
//   },
//   {
//     id: "8",
//     title: "What is Down syndrome?",
//     thumbnail: "/images/syndrome_img.png",
//     videoSrc: "https://www.youtube.com/watch?v=ysz5S6PUM-U",
//   },
// ];

export const WhatNext = ({ sNf, videos, headings }) => {
  const [videoList, setVideoList] = useState([]);
  const [activeVideo, setActiveVideo] = useState(0);
  const [tooltip, showTooltip] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const whatNextHeading = headings?.heading_component?.find(
    (item) => item.key === "snf"
  );
  useEffect(() => {
    const mappedVideos =
      videos?.map((videoItem) => {
        return {
          id: videoItem?.id,
          title: videoItem?.title,
          thumbnail: videoItem?.thumbnail?.url,
          videoSrc: videoItem?.video_src,
        };
      }) || [];
    setVideoList(mappedVideos);
  }, [videos]);

  const getVideoWatchLink = useCallback(
    (index) => {
      const regExp =
        /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      const match = videoList[index]?.videoSrc?.match(regExp);
      return match && match[7].length === 11 ? match[7] : false;
    },
    [videoList]
  );

  const {
    state: { testDisorders },
    // actions: { getStrappiDisorders },
  } = useAuth();

  const getDisorderName = useCallback(
    (key) => {
      const selectedDisorder = testDisorders?.find(
        (disorder) => disorder?.key === key
      );

      return selectedDisorder?.name;
    },
    [testDisorders]
  );

  const getDisorderTooltip = useCallback(
    (key) => {
      const selectedDisorder = testDisorders?.find(
        (disorder) => disorder?.key === key
      );

      return selectedDisorder?.description;
    },
    [testDisorders]
  );

  // const sNfTags = document.getElementsByClassName("MsoListParagraph");
  // const info = [
  // "Detailed anomaly scan and Genetic Sonogram to assess for markers and defects for chromosomal abnormalities.",
  // "Definitive testing through fetal karyotyping to confirm.",
  // sNfTags[0]?.textContent,
  // ];
  return (
    <Box>
      {showModal && (
        <InfoModal
          isOpen={!!showModal}
          onRequestClose={() => setShowModal(false)}
          ariaHideApp={false}
          title={getDisorderName(showModal)}
          info={getDisorderTooltip(showModal)}
          height={{ xs: "35rem" }}
          keyTooltip={showModal}
        />
      )}
      {Array.isArray(sNf) && sNf.length ? (
        <>
          {whatNextHeading && (
            <Flex alignItems="center">
              <Heading
                name={whatNextHeading?.title}
                fontSize={{ xs: "1.8rem", lg: "2.5rem" }}
                lineHeight={{ xs: "2.9rem", lg: "4rem" }}
              />
              {whatNextHeading?.tooltipKey && (
                <Box
                  display="flex"
                  width="1.6rem"
                  height="1.6rem"
                  borderRadius="50%"
                  border="0.2rem solid"
                  borderColor="accent.900"
                  alignItems="center"
                  justifyContent="center"
                  cursor="pointer"
                  onClick={() => setShowModal(whatNextHeading?.tooltipKey)}
                  ml="0.3rem"
                >
                  <Text
                    fontSize="1.2rem"
                    lineHeight="1.5rem"
                    fontWeight="700"
                    color="accent.900"
                    mt="0.3rem"
                  >
                    ?
                  </Text>
                </Box>
              )}
            </Flex>
          )}
          <Box
            // mt={{ xs: "2.3rem", lg: "2.8rem" }}
            maxWidth="100%"
            overFlow="hidden"
            mb="6.5rem"
          >
            {/* <Markdown
              options={{
                overrides: {
                  p: {
                    props: {
                      style: {
                        color: `black`,
                        fontFamily: { xs: "primaryLight", lg: "primaryMedium" },
                        fontWeight: { xs: "300", lg: "500" },
                        fontSize: "1.6rem",
                        width: { xs: "32.5rem", lg: "unset" },
                      },
                    },
                  },
                  span: {
                    props: {
                      style: {
                        color: `black`,
                        fontFamily: { xs: "primaryLight", lg: "primaryMedium" },
                        fontWeight: { xs: "300", lg: "500" },
                        fontSize: "1.6rem",
                        width: { xs: "32.5rem", lg: "unset" },
                      },
                    },
                  },
                },
              }}
            >
              {sNf?.replace(/(<([^>]+)>)/gi, "") || " "}
            </Markdown> */}
            {Array.isArray(sNf) && sNf.length
              ? sNf.map((item, index) => {
                  return (
                    <Box
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      mt={{ xs: "2.3rem", lg: "2.8rem" }}
                      maxWidth="100%"
                      overFlow="hidden"
                      fontSize="1.6rem"
                      // mb="6.5rem"
                    >
                      <Markdown>{item?.suggestions || ""}</Markdown>
                    </Box>
                  );
                })
              : ""}
          </Box>
        </>
      ) : (
        ""
      )}
      {videoList.length > 0 && (
        <Box>
          <Grid
            // mt="6.5rem"
            gridTemplateColumns={{ xs: "1fr", lg: "1fr 0.6fr" }}
            gridColumnGap={{ lg: "3.8rem" }}
            gridRowGap={{ xs: "1.8rem", lg: "0" }}
          >
            <Box maxWidth={{ xs: "85vw", lg: "100%" }}>
              <Box height={{ xs: "20rem", lg: "100%" }}>
                <ReactPlayer
                  url={videoList[activeVideo]?.videoSrc}
                  controls={false}
                  width="100%"
                  height="100%"
                  light={videoList[activeVideo]?.thumbnail || true}
                  playIcon={
                    <Image src="/images/play_button.png" alt="playButton" />
                  }
                />
              </Box>
            </Box>
            <Text
              pt="1.3rem"
              fontSize="1.6rem"
              fontFamily="primaryExtraBold"
              color="black"
              display={{ xs: "block", lg: "none" }}
            >
              {videoList[activeVideo]?.title}
            </Text>
            <Box maxWidth={{ xs: "85vw", lg: "100%" }}>
              {/* <Text
                fontSize="1.6rem"
                lineHeight="2.4rem"
                fontFamily="primaryBlack"
                fontWeight="600"
                color="primary.500"
              >
                Know more about T21 (Down Syndrome)
              </Text> */}
              <Box
                className={
                  isMobile() === true ? "hide-scrollbar" : "coloured-scrollbar"
                }
                // mt={{ xs: "1.4rem", lg: "2.5rem" }}
                height={{ lg: "37.4rem" }}
                mr={{ lg: "6rem" }}
                display={{ xs: "grid", lg: "block" }}
                py={{ xs: "1.4rem", lg: "0" }}
                gridGap={{ xs: "2rem", lg: "0" }}
                gridTemplateColumns={`repeat(${videoList.length}, 1fr)`}
                overflow="auto"
                gridColumn="auto"
                pr={{ xs: "2rem", lg: "0" }}
              >
                {videoList?.map((item, index) => (
                  <Box
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    boxShadow={{
                      xs: "0px 0px 4px rgba(0, 0, 0, 0.25)",
                      lg: "0px 4px 21px 1px rgba(1, 1, 1, 0.1)",
                    }}
                    maxWidth={{ lg: "32.5rem" }}
                    height={{ xs: "13.6rem", lg: "unset" }}
                    width={{ xs: "13.5rem", lg: "unset" }}
                    {...(index && { my: { lg: "1.2rem" } })}
                    borderRadius="0.4rem"
                    onClick={() => setActiveVideo(index)}
                    cursor="pointer"
                  >
                    <Grid gridTemplateColumns={{ lg: "1fr 1fr" }}>
                      <Box>
                        <Image
                          src={
                            item?.thumbnail ||
                            `http://img.youtube.com/vi/${getVideoWatchLink(
                              index
                            )}/0.jpg`
                          }
                          alt="syndrome_img"
                          height={{ lg: "8.1rem" }}
                          width={{ lg: "14.5rem" }}
                        />
                        <Image
                          src="images/play_button.png"
                          alt="play_button"
                          position="absolute"
                          width={{ xs: "2rem", lg: "2.8rem" }}
                          height={{ xs: "2rem", lg: "2.8rem" }}
                          top="35%"
                          left={{ xs: "45%", lg: "5.9rem" }}
                        />
                      </Box>

                      <Text
                        pt={{ xs: "0.9rem", lg: "1.3rem" }}
                        pl={{ xs: "0.8rem", lg: "0" }}
                        fontSize={{ xs: "1.4rem", lg: "1.6rem" }}
                        fontFamily={{
                          xs: "primaryRegular",
                          lg: "primaryExtraBold",
                        }}
                        color="black"
                        maxWidth="80%"
                        overflow="hidden"
                        whiteSpace="nowrap"
                        // textOverflow="ellipsis"
                        // text-overflow="ellipsis"
                        style={{
                          textOverflow: "ellipsis",
                        }}
                        data-tip={item.title}
                        onMouseEnter={() => showTooltip(true)}
                        onMouseLeave={() => {
                          showTooltip(false);
                          setTimeout(() => showTooltip(true), 5);
                        }}
                      >
                        {item.title}
                      </Text>
                      {tooltip && (
                        <ReactTooltip
                          // id={index.toString()}
                          place="top"
                          effect="solid"
                        />
                      )}
                    </Grid>
                  </Box>
                ))}
              </Box>
            </Box>
          </Grid>
          <Text
            pt="1.3rem"
            fontSize="1.6rem"
            fontFamily="primaryExtraBold"
            color="black"
            display={{ xs: "none", lg: "block" }}
          >
            {videoList[activeVideo]?.title}
          </Text>
        </Box>
      )}
    </Box>
  );
};
