import React from "react";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import PhoneInput from "react-phone-input-2";
import OtpInput from "react-otp-input";

import { Box, Text, Flex, Button, Error } from "atoms";

const validationSchema = Yup.object({
  name: Yup.string()
    .required("Required")
    .max(100, "Max 100 characters allowed")
    .min(3, "Min 3 characters required"),
  phone: Yup.string()
    .required("Required")
    .max(10, "Max 100 characters allowed")
    .min(3, "Min 3 characters required"),
});

const customStyles = {
  container: {
    width: "100%",
  },
  input: {
    width: "6.8rem",
    height: "6.8rem",
    margin: "0 1rem",
    borderRadius: "1rem",
    fontSize: "2.5rem",
    lineHeight: "4.1rem",
    fontFamily: "AxiformaBold",
    textAlign: "center",
    color: "#652D90",
  },
};

export const renderCountrySelect = ({ placeholder, field, form }) => {
  return (
    <PhoneInput
      country="in"
      placeholder={placeholder}
      inputProps={{
        name: "phone",
        required: true,
      }}
      onChange={(e) => form.setFieldValue(field.name, e)}
      key={field.value === undefined}
    />
  );
};

export const renderOtpInput = ({ field, form }) => {
  return (
    <OtpInput
      value={field.value}
      onChange={(e) => form.setFieldValue(field.name, e)}
      numInputs={4}
      containerStyle={customStyles.container}
      inputStyle={customStyles.input}
    />
  );
};

export const RegisterForm = ({ type, setType }) => {
  return (
    <>
      <Formik
        initialValues={{
          name: "",
          phone: "",
          otp: "",
          remember_me: false,
        }}
        validationSchema={validationSchema}
      >
        {({ touched, errors }) => {
          return (
            <Form>
              {type === "register" ? (
                <>
                  <Box className="form-control" mt="3.9rem">
                    <Text
                      fontSize={{ xs: "2rem", lg: "1.6rem" }}
                      lineHeight={{ xs: "3.1rem", lg: "2.4rem" }}
                      fontWeight="400"
                      fontFamily="primaryRegular"
                      color="grey.900"
                      mb="0.7rem"
                      letterSpacing="0.07em"
                    >
                      Name
                      <Text as="span" color="accent.100">
                        *
                      </Text>
                    </Text>
                    <Field
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Enter your name"
                      className={
                        touched.name && errors.name ? "input-error" : ""
                      }
                    />
                    <ErrorMessage name="name" component={Error} />
                  </Box>
                  <Box
                    className="form-control"
                    mt={{ xs: "4.2rem", lg: "3.3rem" }}
                  >
                    <Text
                      fontSize={{ xs: "2rem", lg: "1.6rem" }}
                      lineHeight={{ xs: "3.1rem", lg: "2.4rem" }}
                      fontWeight="400"
                      fontFamily="primaryRegular"
                      color="grey.900"
                      mb="0.7rem"
                      letterSpacing="0.07em"
                    >
                      Phone number
                      <Text as="span" color="accent.100">
                        *
                      </Text>
                    </Text>
                    <Field
                      id="phone"
                      name="phone"
                      component={renderCountrySelect}
                      className={
                        touched.phone && errors.phone ? "input-error" : ""
                      }
                    />
                    <ErrorMessage name="phone" component={Error} />
                  </Box>
                  <Button
                    variant="primary"
                    width="100%"
                    textTransform="uppercase"
                    mt="5.7rem"
                    onClick={() => setType("otp")}
                  >
                    Send OTP
                  </Button>

                  <Flex alignItems="center" mt="1.5rem">
                    <Field
                      type="checkbox"
                      id="remember_me"
                      name="remember_me"
                    />
                    <Text
                      fontSize="1.4rem"
                      lineHeight="2.2rem"
                      fontWeight="300"
                      fontFamily="primaryRegular"
                      color="grey.900"
                      ml="1.2rem"
                      letterSpacing="0.07em"
                    >
                      Remember me
                    </Text>
                  </Flex>
                </>
              ) : (
                <>
                  <Box
                    className="form-control"
                    mt={{ xs: "4.9rem", lg: "6.3rem" }}
                    px={{ lg: "3.4rem" }}
                  >
                    <Field
                      id="otp"
                      name="otp"
                      component={renderOtpInput}
                      className={touched.otp && errors.otp ? "input-error" : ""}
                    />
                    <ErrorMessage name="otp" component={Error} />
                  </Box>
                  <Text
                    fontSize="1.6rem"
                    lineHeight={{ xs: "1.6rem", lg: "2.5rem" }}
                    fontWeight="300"
                    fontFamily="primaryLight"
                    color="grey.900"
                    textAlign="center"
                    width="100%"
                    letterSpacing="0.07em"
                    mt={{ xs: "4.8rem", lg: "4.2rem" }}
                  >
                    Resend OTP in{" "}
                    <Text
                      as="span"
                      fontWeight="600"
                      fontFamily="primaryRegular"
                      color="green.900"
                      letterSpacing="0.07em"
                    >
                      00:12
                    </Text>
                  </Text>

                  <Text
                    fontSize="1.6rem"
                    lineHeight="2.5rem"
                    fontWeight="300"
                    fontFamily="primaryLight"
                    color="grey.900"
                    textAlign="center"
                    width="100%"
                    letterSpacing="0.07em"
                    mt={{ xs: "2.7rem", lg: "2.2rem" }}
                  >
                    Don’t Receive OTP?{" "}
                    <Text
                      as="span"
                      fontWeight="600"
                      fontFamily="primaryRegular"
                      color="primary.500"
                      letterSpacing="0.07em"
                      textTransform="uppercase"
                    >
                      RESEND OTP
                    </Text>
                  </Text>
                  <Button
                    variant="primary"
                    width="100%"
                    textTransform="uppercase"
                    mt={{ xs: "4.1rem", md: "3.1rem" }}
                    onClick={() => setType("verified")}
                  >
                    Verify
                  </Button>
                </>
              )}
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
