import React, { useCallback } from "react";
import ReactPlayer from "react-player";
import Markdown from "markdown-to-jsx";

import { Box, Heading, Flex, Image, Text } from "atoms";

export const HomeHero = ({ heading, description, video }) => {
  const getVideoWatchLink = useCallback((url) => {
    const regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url?.match(regExp);
    return match && match[7].length === 11 ? match[7] : false;
  }, []);

  return (
    <>
      <Box
        px={{ xs: "2.8rem", lg: "0" }}
        mt={{ xs: "3rem", lg: "8.9rem" }}
        width="100%"
      >
        <Heading name={heading || ""} />
        <Flex
          flexDirection={{ xs: "column-reverse", lg: "row" }}
          mt={{ xs: "3.1rem", lg: "2.3rem" }}
          px={{ xs: "0.2rem", lg: "2.9rem" }}
        >
          <Box mt={{ xs: "3rem", lg: "0" }}>
            <Markdown
              options={{
                overrides: {
                  p: {
                    props: {
                      style: {
                        fontSize: "1.6rem ",
                        lineHeight: "3.4rem",
                        // maxWidth={{ xs: "37rem", lg: "65rem" }}
                        color: "#313131",
                        fontWeight: "500",
                        fontFamily: "primaryRegular !important",
                        letterSpacing: "0.07em",
                        textTransform: "capitalize",
                      },
                    },
                  },
                  span: {
                    props: {
                      style: {
                        fontSize: "1.6rem ",
                        lineHeight: "3.4rem",
                        // maxWidth={{ xs: "37rem", lg: "65rem" }}
                        color: "#313131",
                        fontWeight: "500",
                        fontFamily: "primaryRegular !important",
                        letterSpacing: "0.07em",
                        textTransform: "capitalize",
                      },
                    },
                  },
                },
              }}
            >
              {description || ""}
            </Markdown>
          </Box>
          <Box ml={{ lg: "7.6rem" }}>
            <Box
              height={{ xs: "20rem", lg: "27.5rem" }}
              width={{ lg: "49.4rem" }}
            >
              <ReactPlayer
                url={video?.link || ""}
                controls={false}
                width="100%"
                height="100%"
                light={
                  video?.image?.url ||
                  `http://img.youtube.com/vi/${getVideoWatchLink(
                    video?.link
                  )}/0.jpg`
                }
                playIcon={
                  <Image src="/images/play_button.png" alt="playButton" />
                }
              />
            </Box>
            <Text
              mt="1.7rem"
              fontSize="1.4rem"
              lineHeight="3rem"
              maxWidth="48rem"
              color="grey.900"
              fontWeight="300"
              fontFamily="primaryLight"
              letterSpacing="0.07em"
              display={{ xs: "block", lg: "block" }}
            >
              {video?.description || ""}
            </Text>
          </Box>
        </Flex>
      </Box>
    </>
  );
};
