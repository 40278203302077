import React, { useState } from "react";

import { Box, Text, Button, Flex, Image } from "atoms";
import { BookCard, EmojiCard, VideoModal } from "molecules";

export const HomeCards = ({ cards }) => {
  const [showVideo, setShowVideo] = useState(false);
  const [emojiPopUp, setEmojiPopUp] = useState(true);
  return (
    <>
      {showVideo && (
        <VideoModal
          isOpen={showVideo}
          onRequestClose={() => setShowVideo(false)}
          ariaHideApp={false}
          title="What is Down syndrome?"
          thumbnail="/images/thumbnail.png"
          videoUrl="https://www.youtube.com/watch?v=ysz5S6PUM-U"
        />
      )}
      <Box display={{ xs: "none", lg: "block" }} mt="8rem">
        {cards?.map((bannerObject, index) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <Box mt="7.5rem" key={index}>
              <BookCard
                gradient={index % 2 ? "white" : "primary"}
                title={bannerObject?.banner?.test_title}
                info={bannerObject?.banner?.test_description}
                btnText="Book a NIPS/NIPT test"
                img={bannerObject?.banner?.bg_desktop?.url}
                btnPrimary={bannerObject?.banner?.button}
                readMore={bannerObject?.banner?.read_more}
                watchVideo={bannerObject?.banner?.watch_video}
                btnLink="https://www.lilacinsights.com/"
              />
            </Box>
          );
        })}
      </Box>

      <Box display={{ xs: "block", lg: "none" }} mt={{ xs: "0", lg: "6rem" }}>
        <Box px="3rem" mt="3rem">
          <EmojiCard
            onClose={() => setEmojiPopUp(false)}
            isVisible={emojiPopUp}
          />
          {/* <Box mt="6rem">
            <Text
              fontSize="2.2rem"
              lineHeight="3rem"
              letterSpacing="0.07rem"
              color="primary.500"
              fontWeight="500"
              fontFamily="primaryRegular"
            >
              Lorem ipsum dolor sit amet, consectetur adipi
            </Text>
            <a
              href="https://www.lilacinsights.com/"
              target="__blank"
              rel="noreferrer"
            >
              <Button width="100%" mt="2.6rem" variant="primary">
                Book a NIPS/NIPT test
              </Button>
            </a>
            <Flex
              alignItems="center"
              justifyContent="space-between"
              mt="0.8rem"
            >
              <Text
                fontSize="1.6rem"
                lineHeight="2.5rem"
                color="secondary.100"
                fontWeight="500"
                fontFamily="primaryRegular"
              >
                Read More
              </Text>
              <Text
                fontSize="1.6rem"
                lineHeight="2.5rem"
                color="secondary.100"
                fontWeight="500"
                fontFamily="primaryRegular"
                onClick={() => setShowVideo(true)}
              >
                Watch Video
              </Text>
            </Flex>
          </Box> */}
        </Box>

        {cards?.map((bannerObject, index) => {
          return (cards?.length % 2 ? !(index % 2) : index % 2) ? (
            <Box
              mt="2.6rem"
              pb="2.4rem"
              pt="6.2rem"
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              background={`url("/images/home-hero-card.png")`}
              backgroundSize="cover"
              backgroundRepeat="none"
            >
              {/* <Image
                src="/images/home-hero-card-xs-1.png"
                alt="home-hero-card-xs-1"
                position="absolute"
                bottom="-1rem"
                zIndex="0"
              />
              <Image
                src="/images/home-hero-card-xs-2.png"
                alt="home-hero-card-xs-2"
                position="absolute"
                bottom="-1rem"
                zIndex="0"
              /> */}
              <Box px="3rem" zIndex="1">
                <Text
                  fontSize="2.2rem"
                  lineHeight="3rem"
                  letterSpacing="0.07rem"
                  color="white"
                  fontWeight="500"
                  fontFamily="primaryRegular"
                >
                  {bannerObject?.banner?.test_title}
                </Text>
                <a
                  href={bannerObject?.banner?.button?.link || ""}
                  target="__blank"
                  rel="noreferrer"
                >
                  <Button
                    width="100%"
                    mt="2.6rem"
                    variant="primary"
                    py="1rem"
                    height="auto !important"
                  >
                    {bannerObject?.banner?.button?.text || ""}
                  </Button>
                </a>
                <Flex
                  alignItems="center"
                  justifyContent={
                    bannerObject?.banner?.read_more ||
                    bannerObject?.banner?.watch_vedio
                      ? bannerObject?.banner?.read_more &&
                        bannerObject?.banner?.watch_vedio
                        ? "space-between"
                        : "flex-end"
                      : ""
                  }
                  mt="1.5rem"
                >
                  {bannerObject?.banner?.read_more && (
                    <a
                      href={bannerObject?.banner?.read_more?.link || ""}
                      target="__blank"
                      rel="noreferrer"
                    >
                      <Text
                        fontSize="1.6rem"
                        lineHeight="2.5rem"
                        color="black"
                        fontWeight="500"
                        fontFamily="primaryRegular"
                        cursor="pointer"
                      >
                        {bannerObject?.banner?.read_more?.text}
                      </Text>
                    </a>
                  )}
                  {bannerObject?.banner?.watch_vedio && (
                    <a
                      href={bannerObject?.banner?.watch_vedio?.link || ""}
                      target="__blank"
                      rel="noreferrer"
                    >
                      <Text
                        fontSize="1.6rem"
                        lineHeight="2.5rem"
                        color="black"
                        fontWeight="500"
                        fontFamily="primaryRegular"
                        onClick={() => setShowVideo(true)}
                        cursor="pointer"
                      >
                        {bannerObject?.banner?.watch_vedio?.text}
                      </Text>
                    </a>
                  )}
                </Flex>
              </Box>
            </Box>
          ) : (
            // eslint-disable-next-line react/no-array-index-key
            <Box pt="6.5rem" px="3.5rem" key={index}>
              <Box width="100%" mx="auto">
                <Text
                  fontSize="2.2rem"
                  lineHeight="3rem"
                  letterSpacing="0.07rem"
                  color="primary.500"
                  fontWeight="500"
                  fontFamily="primaryRegular"
                >
                  {bannerObject?.banner?.test_title}
                </Text>
                <a
                  href={bannerObject?.banner?.button?.link || ""}
                  target="__blank"
                  rel="noreferrer"
                >
                  <Button
                    width="100%"
                    mt="2.6rem"
                    variant="primary"
                    py="1rem"
                    height="auto !important"
                  >
                    {bannerObject?.banner?.button?.text || ""}
                  </Button>
                </a>
                <Flex
                  alignItems="center"
                  // justifyContent="space-between"
                  mt="1.5rem"
                  justifyContent={
                    bannerObject?.banner?.read_more ||
                    bannerObject?.banner?.watch_vedio
                      ? bannerObject?.banner?.read_more &&
                        bannerObject?.banner?.watch_vedio
                        ? "space-between"
                        : "flex-end"
                      : ""
                  }
                >
                  {bannerObject?.banner?.read_more && (
                    <a
                      href={bannerObject?.banner?.read_more?.link || ""}
                      target="__blank"
                      rel="noreferrer"
                    >
                      <Text
                        fontSize="1.6rem"
                        lineHeight="2.5rem"
                        color="secondary.100"
                        fontWeight="500"
                        fontFamily="primaryRegular"
                        cursor="pointer"
                      >
                        {bannerObject?.banner?.read_more?.text}
                      </Text>
                    </a>
                  )}
                  {bannerObject?.banner?.watch_vedio && (
                    <a
                      href={bannerObject?.banner?.watch_vedio?.link || ""}
                      target="__blank"
                      rel="noreferrer"
                    >
                      <Text
                        fontSize="1.6rem"
                        lineHeight="2.5rem"
                        color="secondary.100"
                        fontWeight="500"
                        fontFamily="primaryRegular"
                        onClick={() => setShowVideo(true)}
                        cursor="pointer"
                      >
                        {bannerObject?.banner?.watch_vedio?.text}
                      </Text>
                    </a>
                  )}
                </Flex>
              </Box>
            </Box>
          );
        })}
        {/* <Box mt="3.8rem" pb="2.4rem" pt="6.2rem">
          <Image
            src="/images/home-hero-card-xs-1.png"
            alt="home-hero"
            position="absolute"
            bottom="-1rem"
            zIndex="0"
          />
          <Image
            src="/images/home-hero-card-xs-2.png"
            alt="home-hero"
            position="absolute"
            bottom="-1rem"
            zIndex="0"
          />
          <Box px="3rem" zIndex="1">
            <Text
              fontSize="2.2rem"
              lineHeight="3rem"
              letterSpacing="0.07rem"
              color="white"
              fontWeight="500"
              fontFamily="primaryRegular"
            >
              Lorem ipsum dolor sit amet, consectetur adipi
            </Text>
            <a
              href="https://www.lilacinsights.com/"
              target="__blank"
              rel="noreferrer"
            >
              <Button width="100%" mt="2.6rem" variant="primary">
                Book a NIPS/NIPT test
              </Button>
            </a>
            <Flex
              alignItems="center"
              justifyContent="space-between"
              mt="0.8rem"
            >
              <Text
                fontSize="1.6rem"
                lineHeight="2.5rem"
                color="black"
                fontWeight="500"
                fontFamily="primaryRegular"
              >
                Read More
              </Text>
              <Text
                fontSize="1.6rem"
                lineHeight="2.5rem"
                color="black"
                fontWeight="500"
                fontFamily="primaryRegular"
                onClick={() => setShowVideo(true)}
              >
                Watch Video
              </Text>
            </Flex>
          </Box>
        </Box> */}
      </Box>
    </>
  );
};
