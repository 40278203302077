import { getBearerToken } from "./getTokens";

const generateApiDefaults = () => {
  const headers = {};
  const accessToken = getBearerToken();
  if (accessToken) {
    headers.authorization = `Bearer ${accessToken}`;
    headers.token = accessToken;
  }

  return {
    method: "get",
    headers: {
      ...headers,
      "Access-Control-Allow-Origin": "*",
    },
  };
};

export const makeRequestInit = (overrides) => {
  const init = {
    ...generateApiDefaults(),
    ...overrides,
  };
  return init;
};
