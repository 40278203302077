import React, { useState } from "react";
import ReactTooltip from "react-tooltip";

import { Box, Text, Flex, Grid } from "atoms";
import { InfoModal } from "molecules";
import MemoArrowCard from "assets/icons/ArrowCard";
import { colorCode, colorCodeBgShadow } from "utils/utilities";

export const ResultCard = ({
  id,
  title,
  name,
  result,
  type,
  selectedCard,
  setSelectedCard,
  info,
  keyTooltip,
}) => {
  const [showMore, setShowMore] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [tooltip, showTooltip] = useState(true);

  const handleShowMore = () => {
    if (!showMore) {
      setSelectedCard(id);
      setShowMore(true);
    } else {
      setSelectedCard(null);
      setShowMore(false);
    }
  };

  return (
    <>
      {isHovered && (
        <InfoModal
          isOpen={isHovered}
          onRequestClose={() => setIsHovered(false)}
          ariaHideApp={false}
          title={name}
          info={info}
          height={{ xs: "35rem" }}
          keyTooltip={isHovered}
        />
      )}
      <Box
        maxWidth={{ xs: "35rem", lg: "40rem" }}
        width={{ lg: "40rem" }}
        height="auto"
      >
        {selectedCard && id !== selectedCard && (
          <Box
            position="absolute"
            background="rgba(0, 0, 0, 0.54)"
            zIndex="1"
            height="100%"
            width="100%"
            borderRadius="0.8rem"
            display={{ xs: "none", lg: "block" }}
          />
        )}
        <Flex
          position={showMore ? { xs: "relative", lg: "absolute" } : "relative"}
          zIndex={showMore ? "2" : "0"}
          borderRadius={{ xs: "0.4rem", lg: "0.8rem" }}
          border={{ lg: "0.2rem solid" }}
          borderColor={{ lg: "grey.200" }}
          boxShadow={`0px 1.79459px 8.97297px ${colorCodeBgShadow(type)}`}
        >
          <Box
            width={{ xs: "0.8rem", lg: "1.3rem" }}
            height="auto"
            bg={colorCode(type)}
            borderTopLeftRadius="0.8rem"
            borderBottomLeftRadius="0.8rem"
          />
          <Box
            bg="white"
            pl={{ lg: "4.1rem" }}
            pb={{ xs: "1.9rem", lg: "1.4rem" }}
            width="100%"
            borderTopRightRadius={{ xs: "0.4rem", lg: "0.8rem" }}
            borderBottomRightRadius={{ xs: "0.4rem", lg: "0.8rem" }}
          >
            <Box
              pt={{ xs: "0.8rem", lg: "2.9rem" }}
              pb={{ xs: "0.8rem", lg: "0" }}
              bg={{ xs: "primary.100", lg: "transparent" }}
              pl={{ xs: "1.1rem", lg: "0" }}
              pr={{ xs: "1.5rem", lg: "0" }}
              borderTopRightRadius={{ xs: "0.4rem", lg: "0.8rem" }}
            >
              <Text
                fontSize="1.8rem"
                lineHeight="2.4rem"
                fontFamily="primaryRegular"
                color="grey.600"
                fontWeight="400"
                display={{ xs: "none", lg: "block" }}
              >
                {title}
              </Text>
              <Flex
                alignItems="center"
                justifyContent={{ xs: "space-between", lg: "flex-start" }}
              >
                <Text
                  fontSize={{ xs: "1.6rem", lg: "1.8rem" }}
                  lineHeight={{ xs: "3.2rem", lg: "2.4rem" }}
                  fontFamily="primaryRegular"
                  color={{ xs: "grey.900", lg: "black" }}
                  fontWeight="600"
                >
                  {name}
                </Text>
                <Flex
                  width={{ xs: "1.6rem", lg: "1.8rem" }}
                  height={{ xs: "1.6rem", lg: "1.8rem" }}
                  ml={{ lg: "1.9rem" }}
                  border={{ xs: "0.2rem solid", lg: "0.15rem solid" }}
                  borderColor={{ xs: "accent.900", lg: "grey.800" }}
                  borderRadius="50%"
                  alignItems="center"
                  justifyContent="center"
                  cursor="pointer"
                  onClick={() => setIsHovered(keyTooltip)}
                >
                  <Text
                    fontSize={{ xs: "1.2rem", lg: "1.3rem" }}
                    lineHeight={{ xs: "1.5rem", lg: "1.1rem" }}
                    color={{ xs: "accent.900", lg: "grey.800" }}
                    fontWeight="700"
                    fontFamily="primaryBlack"
                    mt={{ xs: "0.3rem", lg: "0.2rem" }}
                  >
                    ?
                  </Text>
                </Flex>
              </Flex>
              <Box
                height="0.1rem"
                width="33.3rem"
                bg="grey.200"
                mt="1.5rem"
                display={{ xs: "none", lg: "block" }}
              />
            </Box>
            <Box
              display={showMore ? "grid" : { xs: "flex", lg: "grid" }}
              mt={{ xs: "1.3rem", lg: "3rem" }}
              gridTemplateColumns={showMore ? "1fr" : "1fr 1fr"}
              gridRowGap="1.2rem"
              pl={{ xs: showMore ? "0" : "1.1rem", lg: "0" }}
            >
              {result.map((res, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Box key={index} mr={!showMore && { xs: "3.6rem", lg: "0" }}>
                  <Text
                    fontSize={{ xs: "1.4rem", lg: "1.6rem" }}
                    lineHeight="2.4rem"
                    fontWeight="600"
                    fontFamily="primaryMedium"
                    color="secondary.900"
                    py={showMore && { xs: "0.5rem", lg: "0" }}
                    px={showMore && { xs: "1.1rem", lg: "0" }}
                    bg={showMore && { xs: "primary.100", lg: "white" }}
                    mt={showMore && { xs: "2rem", lg: "0" }}
                    mr={showMore ? { xs: "2.3rem", lg: "0" } : "0"}
                  >
                    {res.title}
                  </Text>
                  {showMore ? (
                    <Grid
                      gridTemplateColumns={{ lg: "1fr 1fr" }}
                      gridColumnGap={{ lg: "3.4rem" }}
                      gridRowGap="1.1rem"
                      mt={{ xs: "1rem", lg: "1.1rem" }}
                      mr={{ lg: "2.3rem" }}
                      // mr={
                      //   showMore
                      //     ? { xs: "2rem", lg: "2.3rem" }
                      //     : { xs: "1.1rem", lg: "2.3rem" }
                      // }
                      pl={{ xs: "1.1rem", lg: "0" }}
                    >
                      {/* eslint-disable-next-line no-shadow */}
                      {res.otherResult.map((item, index) => (
                        <Flex
                          alignItems="start"
                          justifyContent="start"
                          // eslint-disable-next-line react/no-array-index-key
                          key={index}
                        >
                          <Text
                            // data-for={index.toString()}
                            fontSize="1.4rem"
                            lineHeight="2.4rem"
                            fontWeight="400"
                            fontFamily="primaryRegular"
                            color="grey.800"
                            maxWidth="50%"
                            overflow="hidden"
                            whiteSpace="nowrap"
                            // textOverflow="ellipsis"
                            // text-overflow="ellipsis"
                            style={{
                              textOverflow: "ellipsis",
                            }}
                            data-tip={item.title}
                            onMouseEnter={() => showTooltip(true)}
                            onMouseLeave={() => {
                              showTooltip(false);
                              setTimeout(() => showTooltip(true), 5);
                            }}
                            flex={1}
                          >
                            {item.title || ""}
                          </Text>
                          {tooltip && (
                            <ReactTooltip
                              // id={index.toString()}
                              place="top"
                              effect="solid"
                            />
                          )}
                          <Text
                            fontSize="1.4rem"
                            lineHeight="2.4rem"
                            fontWeight="600"
                            fontFamily="primaryRegular"
                            color="black"
                            ml="0.6rem"
                            flex={1}
                          >
                            {`: ${item.res}`}
                          </Text>
                        </Flex>
                      ))}
                      <Flex alignItems="center" justifyContent="start">
                        <Text
                          fontSize="1.4rem"
                          lineHeight="2.4rem"
                          fontWeight="400"
                          fontFamily="primaryRegular"
                          color="grey.800"
                        >
                          Result
                        </Text>
                        <Text
                          fontSize="1.4rem"
                          lineHeight="2.4rem"
                          fontWeight="600"
                          fontFamily="primaryRegular"
                          color={colorCode(res?.type)}
                          ml="0.6rem"
                        >
                          {res?.type || "-"}
                        </Text>
                      </Flex>
                    </Grid>
                  ) : (
                    <Text
                      fontSize="1.6rem"
                      lineHeight="2.4rem"
                      fontWeight={{ xs: "600", lg: "500" }}
                      fontFamily="primaryRegular"
                      color={colorCode(res?.type)}
                      mt={{ xs: "0.7rem", lg: "1.1rem" }}
                    >
                      {res?.type || "-"}
                    </Text>
                  )}
                </Box>
              ))}
            </Box>

            <Flex
              mt={
                showMore
                  ? { xs: "1.7rem", lg: "1.5rem" }
                  : { xs: "-1rem", lg: "1.5rem" }
              }
              mr={
                showMore
                  ? { xs: "2rem", lg: "2.3rem" }
                  : { xs: "1.1rem", lg: "2.3rem" }
              }
              justifyContent="flex-end"
              alignItems="center"
              cursor="pointer"
              onClick={() => handleShowMore()}
            >
              <Text
                fontSize="1.4rem"
                lineHeight="2.4rem"
                fontWeight="600"
                fontFamily="primaryBlack"
                color="grey.800"
              >
                {showMore ? "Less" : "More"}
              </Text>
              <Box
                ml="0.5rem"
                width="1.2rem"
                height="0.6rem"
                top={showMore ? "0.2rem" : "-0.2rem"}
                transform={showMore ? "rotate(180deg)" : "rotate(0deg)"}
              >
                <MemoArrowCard />
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Box>
    </>
  );
};
