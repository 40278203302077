import React, { useCallback, useEffect, useState } from "react";
import { Box, Text, Flex, Loader } from "atoms";
import MemoClose from "assets/icons/Close";

import Modal from "react-modal";
import useAuth from "contexts/Auth";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    // marginRight: "-50%",
    marginRight: "-30%",
    transform: "translate(-50%, -50%)",
    borderRadius: "4px",
    padding: "0px",
    border: "none",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 1, 0.6)",
    zIndex: 15,
  },
};

export const InfoModal = ({
  isOpen,
  onRequestClose,
  ariaHideApp,
  title,
  info,
  keyTooltip,
  ...props
}) => {
  const {
    // state: { testDisorders },
    actions: { getTooltips },
  } = useAuth();

  const [loading, setLoading] = useState(false);
  const fetchTooltip = useCallback(async () => {
    if (!loading) {
      setLoading(true);
      !title && !info && (await getTooltips(keyTooltip));
      setLoading(false);
    }
  }, [loading, title, info, keyTooltip, getTooltips]);

  useEffect(() => {
    !title && !info && !loading && fetchTooltip(keyTooltip);
  }, [title, info]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      ariaHideApp={ariaHideApp}
    >
      <Flex
        flexDirection="column"
        overflow="hidden"
        width="fit-content"
        bg="white"
        {...props}
      >
        <Flex
          alignItems="center"
          justifyContent="space-between"
          bg="primary.200"
          py={{ xs: "2.6rem", lg: "3.8rem" }}
          pl={{ xs: "3.4rem", lg: "5.9rem" }}
          pr={{ xs: "2.5rem", lg: "2.7rem" }}
        >
          <Text
            fontSize={{ xs: "2rem", lg: "3rem" }}
            fontFamily="AxiformaBold"
            color="white"
            fontWeight="bold"
            lineHeight="3.2rem"
          >
            {loading ? "Loading ..." : title || keyTooltip || ""}
          </Text>
          <Box
            onClick={onRequestClose}
            cursor="pointer"
            zIndex={2}
            width={{ xs: "1.2rem", lg: "2.7rem" }}
            height={{ xs: "1.2rem", lg: "2.7rem" }}
            top={{ xs: "-0.3rem", lg: "0rem" }}
            color="white"
            {...(loading || !title
              ? { ml: { xs: "3.4rem", lg: "5.9rem" } }
              : { ml: { xs: "1rem", lg: "5.9rem" } })}
          >
            <MemoClose width="100%" height="100%" />
          </Box>
        </Flex>
        <Flex
          pt={{ xs: "2.2rem", lg: "2.7rem" }}
          pb={{ xs: "3.7rem", lg: "4.1rem" }}
          pl={{ xs: "3.4rem", lg: "5.9rem" }}
          pr={{ xs: "2.5rem", lg: "2.7rem" }}
          fontSize={{ xs: "1.6rem", lg: "1.6rem" }}
          fontFamily="primaryMedium"
          color="grey.900"
          fontWeight="500"
          lineHeight="3rem"
          maxWidth={{ xs: "30.4rem", lg: "74.7rem" }}
          overflowX="hidden"
          overflowY="scroll"
        >
          <Loader loading={loading}>
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: info || "No data found" }}
              // overflow-x="hidden"
              // overflow-y="scroll"
            />
            {/* {info.split("<br/>").map((item, index) => (
            <Text
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              mb={{ lg: "1.8rem" }}
              fontSize={{ xs: "1.6rem", lg: "1.6rem" }}
              fontFamily="primaryMedium"
              color="grey.900"
              fontWeight="500"
              lineHeight="3rem"
              display={index === 0 ? "block" : { xs: "none", lg: "block" }}
              maxWidth={{ xs: "30.4rem", lg: "74.7rem" }}
            >
              {item}
            </Text>
          ))} */}
          </Loader>
        </Flex>
      </Flex>
    </Modal>
  );
};
