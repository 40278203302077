import React, { useCallback, useEffect, useState } from "react";

import { Box, Flex, Text } from "atoms";
import MemoClose from "assets/icons/Close";
import { CSSTransition } from "react-transition-group";

export const EmojiCard = ({ onClose, isVisible, onRating }) => {
  const [visibility, setVisiblity] = useState(false);

  const onCloseHandler = useCallback(() => {
    setVisiblity(false);
    setTimeout(() => {
      onClose();
    }, [3000]);
  }, [onClose]);

  useEffect(() => {
    setVisiblity(!!isVisible);
    if (isVisible) {
      setTimeout(() => {
        onCloseHandler();
      }, [10000]);
    }
  }, []);
  return (
    <>
      <CSSTransition
        classNames="popup"
        in={visibility}
        timeout={3000}
        unmountOnExit
      >
        <Box
          width={{ xs: "100%", lg: "36.9rem" }}
          border="1px solid"
          borderColor="secondary.100"
          bg="grey.200"
          pt="1.8rem"
          pb={{ xs: "2.6rem", lg: "2.2rem" }}
          borderRadius="0.4rem"
        >
          <Box
            position="absolute"
            top="-1.8rem"
            // left="-0.08rem"
            width="0"
            height="0"
            borderTop="1.8rem solid transparent"
            borderBottom="1.8rem solid transparent"
            borderLeft="1.8rem solid"
            borderLeftColor="grey.200"
            display={{ xs: "none", lg: "block" }}
          />
          <Box
            zIndex="1"
            position="absolute"
            top="1.4rem"
            right="1.4rem"
            width="0.9rem"
            height="1rem"
            color="secondary.900"
            display={{ xs: "none", lg: "block" }}
            cursor="pointer"
            onClick={onCloseHandler}
          >
            <MemoClose width="100%" height="100%" />
          </Box>
          <Text
            fontFamily="primaryMedium"
            fontSize="1.6rem"
            fontWeight="500"
            lineHeight="2.4rem"
            textAlign="center"
            color="secondary.900"
          >
            How are you feeling today?
          </Text>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            mx={{ xs: "2rem", lg: "3.8rem" }}
            mt={{ xs: "2rem", lg: "1.2rem" }}
          >
            {["☹️", "🙁", "😐", "🙂", "😀"].map((item, index) => (
              <Text
                fontSize="4rem"
                lineHeight="4rem"
                textAlign="center"
                cursor="pointer"
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                onClick={() => onRating(item, index + 1)}
              >
                {item}
              </Text>
            ))}
          </Flex>
        </Box>
      </CSSTransition>
    </>
  );
};
