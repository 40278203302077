import React, { useCallback, useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { useParams } from "react-router-dom";

import { useAuth } from "contexts/Auth";
import { Box, Grid, Flex, Image } from "atoms";
import { SliderWrapper } from "molecules";
import { RegisterUser, VerifyOtp, VerifiedUser } from "organisms";
import MemoLogo from "assets/icons/Logo";

export const LoginPage = () => {
  const [loginPageStrappi, setLoginPageStrappi] = useState({});

  const {
    state: {
      isLoggedIn,
      isOtpSent,
      // --
      requestOtpError,
      requestOtpLoading,
      // --
      otpVerifyError,
      otpVerifyLoading,
      // --
      isResent,
      // --
      userData,
    },
    actions: { requestOTP, verifyOtp, resendOTP, requestOTPbyLinkId },
  } = useAuth();

  const requestOtpHandler = useCallback(
    // ({ name, phone, remember_me }) => {
    (value) => {
      requestOTP(value.phone, value.remember_me);
    },
    [requestOTP]
  );

  const verifyOtpHandler = useCallback(
    ({ otp }) => {
      verifyOtp(userData?.patient?.mobile, otp);
    },
    [verifyOtp, userData]
  );

  const { patientId, barcode } = useParams();

  useEffect(() => {
    if (patientId && barcode) {
      requestOTPbyLinkId(patientId, barcode);
    }
  }, [patientId, barcode, requestOTPbyLinkId]);

  const fetchLoginPageDetails = useCallback(async () => {
    try {
      const test = await fetch(
        // eslint-disable-next-line prefer-template
        `${process.env.REACT_APP_STRAPI}/pages?slug=login`
      );
      const testdata = await test.json();

      setLoginPageStrappi(testdata[0] || {});
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    fetchLoginPageDetails();
  }, [fetchLoginPageDetails]);
  return (
    <Box
      maxWidth={{ lg: "144rem" }}
      // maxHeight={{ lg: "85rem" }}
      height={{ lg: "100vh" }}
      overflow={{ lg: "hidden" }}
    >
      <Grid gridTemplateColumns={{ lg: "1fr 1fr" }}>
        <Box
          width={{ xs: "100vw", lg: "72rem" }}
          height={{ xs: "33.3rem", lg: "100%" }}
          className="login-image-slider"
        >
          <SliderWrapper
            data={loginPageStrappi?.zone?.[0]?.login_image_slider}
          />
        </Box>

        <Flex
          maxWidth={{ xs: "100vw", lg: "72rem" }}
          pb={{ xs: "2rem", lg: "0" }}
          px={{ xs: "2rem", lg: "0" }}
          flexDirection="column"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box
            ml="auto"
            mr="10rem"
            width="22.5rem"
            height="7.3rem"
            display={{ xs: "none", lg: "block" }}
            mt={{ xs: "none", lg: "3.2rem" }}
          >
            <Image
              src={loginPageStrappi?.zone?.[0]?.logo?.url}
              width="100%"
              height="100%"
            />
          </Box>

          {isLoggedIn ? (
            <VerifiedUser
              redirectURL={patientId && barcode ? "/reports" : "/"}
            />
          ) : isOtpSent ? (
            <VerifyOtp
              mobile={userData?.patient?.mobile || ""}
              onSubmit={verifyOtpHandler}
              error={otpVerifyError}
              apiLoading={otpVerifyLoading}
              onResend={{ action: resendOTP, status: isResent }}
            />
          ) : (
            <RegisterUser
              onSubmit={requestOtpHandler}
              error={requestOtpError}
              apiLoading={requestOtpLoading}
            />
          )}
          <Box />
        </Flex>
      </Grid>
    </Box>
  );
};
