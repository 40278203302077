import { loadCookie } from "../cookie";

export const AUTH_TOKEN_COOKIE = "auth-token";
export const REMEMBER_ME = "remember";
export const REFRESH_TOKEN_COOKIE = "refresh-token";

export function getBearerToken() {
  const value = loadCookie(AUTH_TOKEN_COOKIE);
  return value;
}
