import { createReducer } from "utils/Context";
import { AUTH_ACTIONS } from "./actions";

export const initialState = {
  // global identifier for auth
  isLoggedIn: false,
  userData: null,

  // login page
  isOtpSent: false,
  requestOtpError: null,
  requestOtpLoading: false,

  // otp page
  otpVerifyError: null,
  otpVerifyLoading: false,
  isResent: false,

  // patients listing
  patients: null,

  // patients report
  report: null,

  // test details
  testInfo: null,
  testDisorders: null,
  testBanners: null,

  // ratings
  isRated: false,
};

export const authReducer = createReducer({
  // ---
  [AUTH_ACTIONS.requestOtp.loading]: (state) => ({
    ...state,
    requestOtpError: null,
    requestOtpLoading: true,
  }),
  [AUTH_ACTIONS.requestOtp.success]: (state, { payload }) => ({
    ...state,
    isOtpSent: true,
    userData: { ...state.userData, ...(payload || {}) },
    requestOtpLoading: false,
    isRated: false,
  }),
  [AUTH_ACTIONS.requestOtp.failure]: (state, { payload }) => ({
    ...state,
    isLoggedIn: false,
    isOtpSent: false,
    requestOtpLoading: false,
    requestOtpError: payload,
  }),

  // ---
  [AUTH_ACTIONS.otpVerify.loading]: (state) => ({
    ...state,
    otpVerifyError: null,
    otpVerifyLoading: true,
  }),
  [AUTH_ACTIONS.otpVerify.success]: (state, { payload }) => ({
    ...state,
    isLoggedIn: true,
    userData: { ...state.userData, ...(payload || {}) },
    otpVerifyLoading: false,
    isRated: false,
  }),
  [AUTH_ACTIONS.otpVerify.failure]: (state, { payload }) => ({
    ...state,
    isLoggedIn: false,
    otpVerifyLoading: false,
    otpVerifyError: payload,
  }),

  // ---
  [AUTH_ACTIONS.resendOtp.loading]: (state) => ({
    ...state,
    isResent: false,
    otpVerifyError: null,
    otpVerifyLoading: true,
  }),
  [AUTH_ACTIONS.resendOtp.success]: (state) => ({
    ...state,
    isResent: true,
    otpVerifyError: null,
    otpVerifyLoading: false,
    isRated: false,
  }),
  [AUTH_ACTIONS.resendOtp.failure]: (state, { payload }) => ({
    ...state,
    isResent: false,
    otpVerifyLoading: false,
    otpVerifyError: payload,
  }),

  // ---
  [AUTH_ACTIONS.saveUserDetails]: (state, { payload }) => ({
    ...state,
    userData: payload,
  }),

  // ---
  [AUTH_ACTIONS.logout]: (state) => ({
    ...state,
    isLoggedIn: false,
    isOtpSent: false,
    userData: null,
    requestOtpError: null,
    requestOtpLoading: false,
    otpVerifyError: null,
    otpVerifyLoading: false,
  }),

  // ---
  [AUTH_ACTIONS.patientsListing]: (state, { payload }) => ({
    ...state,
    patients: payload,
  }),

  // ---
  [AUTH_ACTIONS.patientReport]: (state, { payload }) => ({
    ...state,
    report: payload,
  }),

  // ---
  [AUTH_ACTIONS.reportTests]: (state, { payload }) => ({
    ...state,
    testInfo: payload,
  }),

  [AUTH_ACTIONS.reportDisorders]: (state, { payload }) => ({
    ...state,
    testDisorders: [...(state.testDisorders || []), ...payload],
  }),

  [AUTH_ACTIONS.reportBanners]: (state, { payload }) => ({
    ...state,
    testBanners: payload,
  }),

  // ---
  [AUTH_ACTIONS.ratingsRecieved]: (state) => ({
    ...state,
    isRated: true,
  }),

  // ---
  [AUTH_ACTIONS.strappiHeadings]: (state, { payload }) => ({
    ...state,
    headings: payload,
  }),
});
