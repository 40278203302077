import React from "react";
import { Link } from "react-router-dom";

import { Box, Text, Grid, Button, Image } from "atoms";

export const TestResultCard = ({ testName, bgImage }) => {
  return (
    <>
      <Box
        display={{ xs: "none", lg: "block" }}
        width="100%"
        borderRadius="1.5rem"
        bg="rgba(142, 16, 232, 0.02)"
        border="1px solid"
        borderColor="secondary.100"
        box-shadow="0px 4px 20px 1px rgba(0, 0, 0, 0.1)"
      >
        <Grid gridTemplateColumns="1fr 0.9fr">
          <Box pt="3.9rem" pl="7.6rem" pb="4.1rem">
            <Text
              fontSize="4.5rem"
              lineHeight="7.4rem"
              color="grey.900"
              fontWeight="700"
              fontFamily="primaryRegular"
              letterSpacing="0.07em"
              maxWidth="52rem"
              textTransform="capitalize"
            >
              Your test result for{" "}
              <Text
                fontSize="4.5rem"
                lineHeight="7.4rem"
                as="span"
                color="secondary.900"
              >
                {testName || ""}
              </Text>{" "}
              is ready.
            </Text>

            <Link to="/reports">
              <Button variant="primary" mt="5.4rem" width="27.8rem">
                View Report
              </Button>
            </Link>
          </Box>
          <Box>
            <Box position="absolute" bottom="0rem">
              <Image
                src="/images/test-result-card-img.png"
                alt="test-result-card-img"
                width="59.9rem"
                heigth="32.5rem"
              />
            </Box>
            <Box position="absolute" bottom="-0.8rem">
              <Image
                src="/images/test-result-card-bg.png"
                alt="test-result-card-bg"
              />
            </Box>
          </Box>
        </Grid>
      </Box>

      <Box
        display={{ xs: "block", lg: "none" }}
        // width="100%"
        // pt="4.4rem"
        // mt="3.3rem"
        // pb="4rem"
        // minHeight="24.3rem"
        px="2.8rem"
        pt="4.6rem"
        pb="2.7rem"
        // mx="2.8rem"
        // background="url(/images/home-hero-card-bg-xs.png)"
        background={`url("${bgImage}")`}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
      >
        <Box
          width="100%"
          height="100%"
          position="absolute"
          top="0"
          left="0"
          bg="black"
          opacity="0.7"
        />
        {/* <Image
          src="/images/home-hero-card-bg-xs.png"
          alt="home-hero-card-bg-xs"
          position="absolute"
          top="0"
          borderRadius="0.4rem"
        /> */}
        <Text
          fontSize="2.2rem"
          lineHeight="3rem"
          letterSpacing="0.07rem"
          fontWeight="600"
          color="white"
          textAlign="center"
          fontFamily="primaryMedium"
        >
          Report ready!
        </Text>
        <Text
          fontSize="1.3rem"
          lineHeight="4rem"
          letterSpacing="0.07rem"
          color="white"
          fontWeight="600"
          textAlign="center"
          fontFamily="primaryMedium"
        >
          Your test result for {testName || ""} is ready.
        </Text>

        <Link to="/reports">
          <Button
            width="22rem"
            variant="primary"
            textTransform="uppercase"
            mx="auto"
            mt="3.6rem"
          >
            View report
          </Button>
        </Link>
      </Box>
    </>
  );
};
