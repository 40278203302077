import * as Yup from "yup";

/**
 * @param {string} name
 * @param {string} phone
 */
export const registerUserSchema = Yup.object({
  // name: Yup.string()
  //   .required("Required")
  //   .max(100, "Max 100 characters allowed")
  //   .min(3, "Min 3 characters required"),
  phone: Yup.string()
    .required("Required")
    .max(10, "Max 100 characters allowed")
    .min(3, "Min 3 characters required"),
});

/**
 * @param {string} otp
 */

export const otpVerifySchema = Yup.object({
  otp: Yup.string().required("Enter OTP"),
});
