export class ServerException extends Error {
  constructor({ statusCode, message }) {
    super(message);

    this.message = message || "An unknown error occurred";
    this.status = statusCode || 520;
  }

  status;

  message;

  toString() {
    return `${this.status}: ${this.message}`;
  }
}
