import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import PhoneInput from "react-phone-input-2";

import { Box, Text, Button, Flex, Error } from "atoms";
import { registerUserSchema } from "utils/validation";

export const renderCountrySelect = ({ placeholder, field, form }) => {
  return (
    <PhoneInput
      country="in"
      placeholder={placeholder}
      inputProps={{
        name: "phone",
        required: true,
      }}
      onChange={(e) => form.setFieldValue(field.name, e.slice(2))}
      key={field.value === undefined}
    />
  );
};

export const RegisterUser = ({ onSubmit, error, apiLoading }) => {
  return (
    <Box
      maxWidth={{ xs: "36rem", lg: "52rem" }}
      mx="auto"
      bg="white"
      boxShadow={{ lg: "0px 4px 21px 1px rgba(1, 1, 1, 0.1)" }}
      borderRadius="1rem"
      mt={{ xs: "5.1rem", lg: "0" }}
      px={{ lg: "9.6rem" }}
      pt={{ lg: "4rem" }}
      pb="6rem"
    >
      <Text
        fontSize={{ xs: "1.4rem", lg: "2.2rem" }}
        lineHeight={{ xs: "1.8rem", lg: "3.4rem" }}
        fontWeight="400"
        fontFamily="primaryRegular"
        color="grey.900"
        textAlign="center"
        maxWidth={{ xs: "29.4rem", lg: "unset" }}
        mx="auto"
        letterSpacing="0.07em"
      >
        Please login to continue
      </Text>

      {error && (
        <Text
          fontSize={{ xs: "1.4rem", lg: "1.6rem" }}
          lineHeight={{ xs: "1.8rem", lg: "3.5rem" }}
          fontWeight="400"
          fontFamily="primaryRegular"
          color="accent.100"
          textAlign="center"
          maxWidth={{ xs: "90%", lg: "41.5rem" }}
          mx="auto"
          letterSpacing="0.07em"
        >
          {error}
        </Text>
      )}

      <Formik
        initialValues={{
          name: "",
          phone: "",
          remember_me: false,
        }}
        validationSchema={registerUserSchema}
        onSubmit={onSubmit ?? undefined}
        enableReinitialize
      >
        {({ touched, errors }) => {
          return (
            <Form>
              <>
                <Box
                  className="form-control"
                  mt={{ xs: "4.2rem", lg: "3.3rem" }}
                >
                  <Text
                    fontSize={{ xs: "1.6rem", lg: "1.6rem" }}
                    lineHeight={{ xs: "3.1rem", lg: "2.4rem" }}
                    fontWeight="400"
                    fontFamily="primaryRegular"
                    color="grey.900"
                    mb="0.7rem"
                    letterSpacing="0.07em"
                  >
                    Phone number
                    <Text as="span" color="accent.100">
                      *
                    </Text>
                  </Text>
                  <Field
                    id="phone"
                    name="phone"
                    component={renderCountrySelect}
                    className={
                      touched.phone && errors.phone ? "input-error" : ""
                    }
                  />
                  <ErrorMessage name="phone" component={Error} />
                </Box>
                <Button
                  variant="primary"
                  width="100%"
                  textTransform="uppercase"
                  mt="5.7rem"
                  type="submit"
                  loading={apiLoading}
                >
                  Send OTP
                </Button>

                <Flex alignItems="center" mt="1.5rem">
                  <Field type="checkbox" id="remember_me" name="remember_me" />
                  <Text
                    fontSize="1.4rem"
                    lineHeight="2.2rem"
                    fontWeight="300"
                    fontFamily="primaryRegular"
                    color="grey.900"
                    ml="1.2rem"
                    letterSpacing="0.07em"
                  >
                    Remember me
                  </Text>
                </Flex>
              </>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};
