import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Box, Image, Text, Flex } from "atoms";
import MemoArrow from "assets/icons/Arrow";
import { isMobile } from "utils/utilities";

export const HomeSlider = ({ cards }) => {
  const homeSlider = React.createRef();

  const gotoNext = () => {
    homeSlider.current.slickNext();
  };

  const gotoPrev = () => {
    homeSlider.current.slickPrev();
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile() ? 1 : 4,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <Box mt="8.1rem" mx={{ xs: "3rem", lg: "0" }}>
      <Box
        className="home-slider"
        css={{
          ".slick-track": {
            display: "flex !important",
          },

          ".slick-slide": {
            height: "inherit !important",
          },
          ".slick-slide > div": {
            height: "100% !important",
          },
          ".slick-slider > .slick-dots": {
            bottom: "-7rem !important",
          },
        }}
        mx={{ xs: "4rem", lg: "0rem" }}
      >
        <Slider {...settings} ref={homeSlider}>
          {cards?.map((card, index) => (
            <Box
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              width="29rem"
              height="100%"
              border="4px solid"
              borderColor="primary.200"
              borderRadius="1.6rem"
              pt={{ xs: "2rem", lg: "4.2rem" }}
              pb="3.2rem"
            >
              <Box width="6rem" height="6rem" mx="auto">
                <Image src={card?.image?.url} alt="hospital" />
              </Box>
              <Text
                mt="1.5rem"
                fontSize={{ xs: "3.2rem", lg: "3.2rem" }}
                lineHeight="4.8rem"
                textAlign="center"
                color="secondary.900"
                fontWeight="600"
                fontFamily="primaryRegular"
              >
                {card?.heading}
              </Text>
              <Text
                maxWidth="18rem"
                fontSize={{ xs: "1.6rem", lg: "1.6rem" }}
                lineHeight="2.4rem"
                textAlign="center"
                color="grey.500"
                fontWeight="400"
                fontFamily="primaryRegular"
                mx="auto"
              >
                {card?.description}
              </Text>
            </Box>
          ))}
        </Slider>
      </Box>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        mt={{ xs: "3rem", lg: "8rem" }}
      >
        <Flex
          width="5.6rem"
          height="5.6rem"
          borderRadius="50%"
          boxShadow="0px 4px 8px rgba(0, 0, 0, 0.4)"
          opacity="0.6"
          border="0.2rem solid"
          borderColor="primary.200"
          alignItems="center"
          justifyContent="center"
          onClick={() => gotoPrev()}
          cursor="pointer"
        >
          <MemoArrow width="1.9rem" height="1.6rem" />
        </Flex>

        <Flex
          width="5.6rem"
          height="5.6rem"
          borderRadius="50%"
          border="0.2rem solid"
          borderColor="primary.200"
          boxShadow="0px 4px 8px rgba(0, 0, 0, 0.4)"
          opacity="0.6"
          alignItems="center"
          justifyContent="center"
          onClick={() => gotoNext()}
          cursor="pointer"
        >
          <Box transform="rotate(180deg)" top="-0.2rem">
            <MemoArrow width="1.9rem" height="1.6rem" />
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};
