import React, { useCallback, useState } from "react";

import { Box, Grid, Text, Flex } from "atoms";
import { colorCode, colorCodeTextBg, colorCodeBgShadow } from "utils/utilities";
import { InfoModal } from "molecules/Modals";
import useAuth from "contexts/Auth";

export const MOMCard = ({ title, value, type, keyTooltip }) => {
  const [showModal, setShowModal] = useState(false);

  const {
    state: { testDisorders },
    // actions: { getStrappiDisorders },
  } = useAuth();

  const getDisorderName = useCallback(
    (key) => {
      const selectedDisorder = testDisorders?.find(
        (disorder) => disorder?.key === key
      );

      return selectedDisorder?.name;
    },
    [testDisorders]
  );

  const getDisorderTooltip = useCallback(
    (key) => {
      const selectedDisorder = testDisorders?.find(
        (disorder) => disorder?.key === key
      );

      return selectedDisorder?.description;
    },
    [testDisorders]
  );

  return (
    <>
      {showModal && (
        <InfoModal
          isOpen={!!showModal}
          onRequestClose={() => setShowModal(false)}
          ariaHideApp={false}
          title={getDisorderName(showModal)}
          info={getDisorderTooltip(showModal)}
          height={{ xs: "35rem" }}
          keyTooltip={showModal}
        />
      )}
      <Flex
        boxShadow={`0px 1.79459px 8.97297px ${colorCodeBgShadow(type)}`}
        // boxShadow={{
        //   xs: "0px 1.79459px 8.97297px rgba(36, 183, 0, 0.17)",
        //   lg: "0px 1.79459px 8.97297px rgba(0, 0, 0, 0.1)",
        // }}
        borderRadius="0.4rem"
      >
        <Box
          width="0.9rem"
          height="auto"
          bg={colorCode(type)}
          borderTopLeftRadius="0.4rem"
          borderBottomLeftRadius="0.4rem"
        />
        <Grid
          gridTemplateColumns="1fr 0.6fr"
          bg="white"
          borderTopRightRadius="0.4rem"
          borderBottomRightRadius="0.4rem"
          width="100%"
        >
          <Flex alignItems="center">
            <Text
              py="1.4rem"
              pl="1.9rem"
              fontSize="1.4rem"
              lineHeight="2.4rem"
              fontFamily="primaryMedium"
              fontWeight="600"
              color="grey.900"
            >
              {title}
            </Text>
            <Flex
              width={{ xs: "1.6rem", lg: "1.8rem" }}
              height={{ xs: "1.6rem", lg: "1.8rem" }}
              ml="1.9rem"
              border={{ xs: "0.2rem solid", lg: "0.15rem solid" }}
              borderColor={{ xs: "accent.900", lg: "grey.800" }}
              borderRadius="50%"
              alignItems="center"
              justifyContent="center"
              cursor="pointer"
              onClick={() => setShowModal(keyTooltip)}
            >
              <Text
                fontSize={{ xs: "1.2rem", lg: "1.3rem" }}
                lineHeight={{ xs: "1.5rem", lg: "1.1rem" }}
                color={{ xs: "accent.900", lg: "grey.800" }}
                fontWeight="700"
                fontFamily="primaryBlack"
                mt={{ xs: "0.3rem", lg: "0.2rem" }}
              >
                ?
              </Text>
            </Flex>
          </Flex>

          <Flex
            bg={colorCodeTextBg(type)}
            alignItems="center"
            justifyContent="center"
            borderTopRightRadius="0.4rem"
            borderBottomRightRadius="0.4rem"
          >
            <Text
              textAlign="center"
              fontSize="1.6rem"
              lineHeight="2.4rem"
              fontFamily="primaryMedium"
              fontWeight="600"
              color={colorCode(type)}
            >
              {value}
            </Text>
          </Flex>
        </Grid>
      </Flex>
    </>
  );
};
