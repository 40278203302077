import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Box, Image, Text } from "atoms";
import { isMobile } from "utils/utilities";
import Markdown from "markdown-to-jsx";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  variableWidth: true,
  autoplay: true,
  autoplaySpeed: 4000,
};

export const SliderWrapper = ({ data }) => {
  return (
    <Slider {...settings}>
      {data?.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Box key={index}>
          <Box
            background="linear-gradient(180deg, rgba(101, 45, 144, 0.75) 12.55%, rgba(112, 49, 164, 0) 78.31%)"
            // width={{ xs: "42.8rem", lg: "72rem" }}
            width={{ xs: "42.8rem", sm: "100vw", lg: "72rem" }}
            height={{ xs: "33.3rem", lg: "100%" }}
          >
            <Image
              src={isMobile() ? item?.mobile_img?.url : item?.desktop_img?.url}
              alt="login-bg"
              height={{ xs: "100%", lg: "100vh" }}
              objectfit="cover"
              style={{ opacity: "0.62" }}
            />
          </Box>
          <Box
            display={{ xs: "block", lg: "block" }}
            position="absolute"
            {...(isMobile()
              ? { bottom: "0rem", left: "0rem", width: "80%" }
              : { top: "8.2rem", left: "12.7rem" })}
            px=".8rem"
            // top="8.2rem"
            // left="12.7rem"
          >
            <Text
              fontSize={{ xs: "2.2rem", lg: "3.2rem" }}
              lineHeight="3.8rem"
              fontWeight="600"
              fontFamily="primaryBlack"
              color="white"
              letterSpacing="0.07em"
            >
              {item?.heading}
              {/* Lorem ipsum dolor sit amet dole Rkme Ipsum Sito! */}
            </Text>
            <Box width="8rem" height="0.2rem" bg="white" mt="2rem" />
            <Text
              maxWidth="43.8rem"
              fontSize={{ xs: "1.2rem", lg: "1.4rem" }}
              lineHeight="2.3rem"
              fontWeight="500"
              fontFamily="primaryMedium"
              color="white"
              mt="1.8rem"
              letterSpacing="0.07em"
            >
              <Markdown>{item?.description}</Markdown>
              {/* consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
              labore et dolore magna aliqua */}
            </Text>
          </Box>
        </Box>
      ))}
    </Slider>
  );
};
