import * as React from "react";

function Close(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 27 27" fill="none" {...props}>
      <path
        d="M3 3l21 21m0-21L3 24 24 3z"
        stroke="currentColor"
        strokeWidth={4.846}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoClose = React.memo(Close);
export default MemoClose;
