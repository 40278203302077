import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import OtpInput from "react-otp-input";

import { Box, Text, Button, Error } from "atoms";
import { otpVerifySchema } from "utils/validation";

const customStyles = {
  container: {
    width: "100%",
  },
  input: {
    width: "100%",
    height: "6.8rem",
    margin: "0 0.4rem",
    borderRadius: "1rem",
    fontSize: "2.5rem",
    lineHeight: "4.1rem",
    fontFamily: "AxiformaBold",
    textAlign: "center",
    color: "#652D90",
  },
};

export const renderOtpInput = ({ field, form }) => {
  return (
    <OtpInput
      value={field.value}
      onChange={(e) => form.setFieldValue(field.name, e)}
      numInputs={6}
      containerStyle={customStyles.container}
      inputStyle={customStyles.input}
    />
  );
};

export const VerifyOtp = ({
  mobile,
  onSubmit,
  error,
  apiLoading,
  onResend,
}) => {
  const [otpWrong, setOtpWrong] = useState(false);
  const [timerValue, setTimerValue] = useState(30);

  useEffect(() => {
    const timer =
      timerValue > 0 && setInterval(() => setTimerValue(timerValue - 1), 1000);
    return () => clearInterval(timer);
  }, [timerValue]);

  useEffect(() => {
    let timer;
    if (onResend.status) {
      timer = setTimeout(() => {
        setTimerValue(30);
      }, [5000]);
    }
    return () => clearInterval(timer);
  }, [onResend.status]);

  useEffect(() => {
    error && setOtpWrong(true);
  }, [error]);

  return (
    <>
      <Box
        maxWidth={{ xs: "36rem", lg: "52rem" }}
        mx="auto"
        bg="white"
        boxShadow={{ lg: "0px 4px 21px 1px rgba(1, 1, 1, 0.1)" }}
        borderRadius="1rem"
        mt={{ xs: "4.8rem", lg: "0" }}
        px={{ lg: "5.2rem" }}
        pt={{ lg: "4rem" }}
        pb="6rem"
      >
        <Text
          fontSize={{ xs: "1.4rem", lg: "2.2rem" }}
          lineHeight={{ xs: "1.8rem", lg: "3.5rem" }}
          fontWeight="400"
          fontFamily="primaryRegular"
          color={otpWrong && error ? "accent.100" : "grey.900"}
          textAlign="center"
          maxWidth={{ xs: "90%", lg: "41.5rem" }}
          mx="auto"
          letterSpacing="0.07em"
          onClick={() => setOtpWrong(!otpWrong)}
        >
          {otpWrong && error ? `${error}` : `Enter the verification code sent`}
          <Text
            fontSize={{ xs: "1.4rem", lg: "2.2rem" }}
            lineHeight={{ xs: "1.8rem", lg: "3.5rem" }}
            fontWeight="900"
            fontFamily="primaryRegular"
            color={otpWrong && error ? "accent.100" : "grey.900"}
            textAlign="center"
            maxWidth={{ xs: "90%", lg: "41.5rem" }}
            mx="auto"
            letterSpacing="0.07em"
          >
            {otpWrong && error
              ? `${error}`
              : `to mobile *${mobile?.slice(mobile?.length - 3)}`}
          </Text>
        </Text>
        <Formik
          initialValues={{
            otp: "",
          }}
          validationSchema={otpVerifySchema}
          onSubmit={onSubmit ?? undefined}
          enableReinitialize
        >
          {({ touched, errors }) => {
            return (
              <Form>
                <Box
                  className="form-control"
                  mt={{ xs: "4.9rem", lg: "6.3rem" }}
                  px={{ lg: "3.4rem" }}
                >
                  <Field
                    id="otp"
                    name="otp"
                    component={renderOtpInput}
                    className={touched.otp && errors.otp ? "input-error" : ""}
                  />
                  <ErrorMessage name="otp" component={Error} />
                </Box>
                {timerValue ? (
                  <Text
                    fontSize="1.6rem"
                    lineHeight={{ xs: "1.6rem", lg: "2.5rem" }}
                    fontWeight="300"
                    fontFamily="primaryLight"
                    color="grey.900"
                    textAlign="center"
                    width="100%"
                    letterSpacing="0.07em"
                    mt={{ xs: "4.8rem", lg: "4.2rem" }}
                  >
                    Resend OTP in{" "}
                    <Text
                      as="span"
                      fontWeight="600"
                      fontFamily="primaryRegular"
                      color="green.900"
                      letterSpacing="0.07em"
                    >
                      00:{timerValue < 10 && "0"}
                      {timerValue}
                    </Text>
                  </Text>
                ) : onResend.status ? (
                  <Text
                    fontSize="1.6rem"
                    lineHeight={{ xs: "1.6rem", lg: "2.5rem" }}
                    fontWeight="300"
                    fontFamily="primaryLight"
                    color="grey.900"
                    textAlign="center"
                    width="100%"
                    letterSpacing="0.07em"
                    mt={{ xs: "4.8rem", lg: "4.2rem" }}
                  >
                    Otp Sent
                  </Text>
                ) : (
                  <Text
                    fontSize="1.6rem"
                    lineHeight="2.5rem"
                    fontWeight="300"
                    fontFamily="primaryLight"
                    color="grey.900"
                    textAlign="center"
                    width="100%"
                    letterSpacing="0.07em"
                    mt={{ xs: "2.7rem", lg: "2.2rem" }}
                  >
                    Don’t Receive OTP?{" "}
                    <Text
                      as="span"
                      fontWeight="600"
                      fontFamily="primaryRegular"
                      color="primary.500"
                      letterSpacing="0.07em"
                      textTransform="uppercase"
                      cursor="pointer"
                      onClick={() => onResend.action(mobile)}
                    >
                      RESEND OTP
                    </Text>
                  </Text>
                )}

                <Button
                  variant="primary"
                  width="100%"
                  textTransform="uppercase"
                  mt={{ xs: "4.1rem", md: "3.1rem" }}
                  type="submit"
                  loading={apiLoading}
                >
                  Verify
                </Button>
              </Form>
            );
          }}
        </Formik>
        {otpWrong && error && (
          <Text
            fontSize="1.6rem"
            lineHeight="2.5rem"
            fontWeight="300"
            fontFamily="primaryLight"
            color="grey.900"
            textAlign="center"
            width="100%"
            letterSpacing="0.07em"
            mt="1rem"
            display={{ xs: "none", lg: "block" }}
          >
            Incorrect mobile number?{" "}
            <Text
              as="span"
              fontWeight="600"
              fontFamily="primaryRegular"
              color="primary.500"
              letterSpacing="0.07em"
            >
              login
            </Text>
          </Text>
        )}
      </Box>
      <Box display={{ xs: "none", lg: "block" }} width="100%">
        <Text
          fontSize="1.2rem"
          lineHeight="1.9rem"
          fontWeight="400"
          fontFamily="primaryRegular"
          color="accent.100"
          textAlign="center"
        >
          Please do not close this window till the verification is completed
        </Text>
      </Box>
    </>
  );
};
