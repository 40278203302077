import * as React from "react";

function LogoRound(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 89 89" fill="none" {...props}>
      <circle cx={44.5} cy={44.5} r={44.5} fill="#FFFEFF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.06 38.353c.025 1.648-1.28 3.004-2.914 3.031-1.636.026-2.983-1.29-3.008-2.938-.025-1.646 1.28-3.003 2.914-3.03a2.97 2.97 0 013.008 2.937zM18 28.498c6.646-1.394 14.294 2.06 19.428 6.026 5.356 4.137 7.52 9.99 9.39 16.31 3.474 11.747 9.313 19.935 20.93 23.356-13.917-4.096-14.305-23.636-19.984-34.188C42.144 29.558 29.07 26.162 18 28.498zm54 11.337s-25.16 7.964-5.77 33.421c0 0-11.082-20.215 5.77-33.421zM32.962 22.75c.034 2.105-1.633 3.836-3.72 3.868-2.09.033-3.809-1.645-3.84-3.748-.033-2.105 1.631-3.838 3.72-3.87 2.088-.032 3.808 1.646 3.84 3.75z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoLogoRound = React.memo(LogoRound);
export default MemoLogoRound;
